import { logoutUser } from '../Redux/Slices/authSlice';
import { store } from '../Redux/store';

export const profileOptions = [
    {
        route: '/profile',
        value: 'My Profile',
    },
    {
        route: '/bookings',
        value: 'My Bookings',
    },
    {
        key: 'signOut',
        value: 'Sign Out',
        onClick: () => store.dispatch(logoutUser())
    },
]