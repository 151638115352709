
const SearchIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 28 28" fill="none">
            <path d="M12.8333 24.2084C6.55668 24.2084 1.45834 19.11 1.45834 12.8334C1.45834 6.55671 6.55668 1.45837 12.8333 1.45837C19.11 1.45837 24.2083 6.55671 24.2083 12.8334C24.2083 19.11 19.11 24.2084 12.8333 24.2084ZM12.8333 3.20837C7.52501 3.20837 3.20834 7.52504 3.20834 12.8334C3.20834 18.1417 7.52501 22.4584 12.8333 22.4584C18.1417 22.4584 22.4583 18.1417 22.4583 12.8334C22.4583 7.52504 18.1417 3.20837 12.8333 3.20837Z" fill="#1782CA" />
            <path d="M23.52 26.5883C23.4267 26.5883 23.3333 26.5767 23.2517 26.565C22.7033 26.495 21.7117 26.1217 21.1517 24.4533C20.86 23.5783 20.965 22.7033 21.4433 22.0383C21.9217 21.3733 22.7267 21 23.6483 21C24.8383 21 25.7717 21.455 26.1917 22.26C26.6117 23.065 26.495 24.0917 25.83 25.0833C25.0017 26.3317 24.1033 26.5883 23.52 26.5883ZM22.82 23.905C23.0183 24.5117 23.2983 24.815 23.485 24.8383C23.6717 24.8617 24.0217 24.64 24.3833 24.115C24.7217 23.6133 24.745 23.2517 24.6633 23.0883C24.5817 22.925 24.255 22.75 23.6483 22.75C23.2867 22.75 23.0183 22.8667 22.8667 23.065C22.7267 23.2633 22.7033 23.5667 22.82 23.905Z" fill="#1782CA" />
        </svg>
    )
}

export default SearchIcon