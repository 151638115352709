import React from 'react'

const Badge = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="29" viewBox="0 0 33 29" fill="none">
            <path d="M30.7387 28.1338H2.26011C1.66078 28.1328 1.08629 27.8942 0.662613 27.4703C0.238937 27.0464 0.000649929 26.4718 3.3535e-07 25.8725V7.73389C-0.000325999 7.13392 0.23753 6.55836 0.661312 6.13366C1.08509 5.70895 1.66014 5.46985 2.26011 5.46887H13.1491C13.312 5.46887 13.4682 5.53358 13.5834 5.64875C13.6986 5.76393 13.7633 5.92015 13.7633 6.08303V9.44003H19.2355V6.08303C19.2355 5.92015 19.3002 5.76393 19.4153 5.64875C19.5305 5.53358 19.6867 5.46887 19.8496 5.46887H30.7387C31.338 5.46952 31.9126 5.70781 32.3365 6.13148C32.7604 6.55516 32.999 7.12965 33 7.72898V25.8725C32.9993 26.472 32.7609 27.0468 32.337 27.4708C31.913 27.8947 31.3382 28.1332 30.7387 28.1338ZM2.26011 6.69719C1.98656 6.69752 1.72431 6.80633 1.53088 6.99975C1.33745 7.19318 1.22864 7.45543 1.22832 7.72898V25.8725C1.22832 26.1462 1.33699 26.4088 1.53045 26.6025C1.72391 26.7962 1.98635 26.9052 2.26011 26.9055H30.7387C31.0125 26.9052 31.2751 26.7962 31.4688 26.6026C31.6624 26.4089 31.7714 26.1463 31.7717 25.8725V7.73389C31.7714 7.46013 31.6624 7.1977 31.4687 7.00423C31.275 6.81077 31.0124 6.7021 30.7387 6.7021H20.4638V10.0591C20.4638 10.222 20.3991 10.3782 20.2839 10.4934C20.1687 10.6086 20.0125 10.6733 19.8496 10.6733H13.1491C12.9863 10.6733 12.83 10.6086 12.7149 10.4934C12.5997 10.3782 12.535 10.222 12.535 10.0591V6.69719H2.26011Z" fill="#A8A8A8" />
            <path d="M19.8503 10.6733H13.1498C12.9869 10.6733 12.8307 10.6086 12.7155 10.4934C12.6003 10.3782 12.5356 10.222 12.5356 10.0591V3.95071C12.5382 3.16769 12.7727 2.40297 13.2094 1.75305C13.6461 1.10312 14.2655 0.597105 14.9895 0.298837C15.7135 0.000568631 16.5096 -0.0765861 17.2774 0.0771066C18.0452 0.230799 18.7503 0.608454 19.3037 1.16242C20.0365 1.90683 20.4526 2.90616 20.4644 3.95071V10.0591C20.4644 10.222 20.3997 10.3782 20.2846 10.4934C20.1694 10.6086 20.0132 10.6733 19.8503 10.6733ZM13.764 9.44497H19.2361V3.95071C19.2244 3.22962 18.9338 2.54111 18.4254 2.02961C18.1702 1.77504 17.8671 1.57353 17.5335 1.43671C17.2 1.29989 16.8427 1.23046 16.4822 1.23244C15.7633 1.24198 15.0765 1.53182 14.5681 2.04023C14.0597 2.54864 13.7698 3.23545 13.7603 3.95439L13.764 9.44497Z" fill="#A8A8A8" />
            <path d="M13.0005 22.0723H3.69228C3.5294 22.0723 3.37319 22.0076 3.25801 21.8924C3.14283 21.7772 3.07812 21.621 3.07812 21.4581V12.1499C3.07812 11.987 3.14283 11.8308 3.25801 11.7156C3.37319 11.6005 3.5294 11.5358 3.69228 11.5358H13.0005C13.1634 11.5358 13.3196 11.6005 13.4348 11.7156C13.5499 11.8308 13.6146 11.987 13.6146 12.1499V21.4581C13.6146 21.621 13.5499 21.7772 13.4348 21.8924C13.3196 22.0076 13.1634 22.0723 13.0005 22.0723ZM4.30644 20.844H12.3863V12.7641H4.30644V20.844Z" fill="#A8A8A8" />
            <path d="M29.4965 12.7641H19.848C19.6852 12.7641 19.5289 12.6994 19.4138 12.5842C19.2986 12.469 19.2339 12.3128 19.2339 12.1499C19.2339 11.987 19.2986 11.8308 19.4138 11.7156C19.5289 11.6005 19.6852 11.5358 19.848 11.5358H29.4965C29.6594 11.5358 29.8156 11.6005 29.9308 11.7156C30.0459 11.8308 30.1106 11.987 30.1106 12.1499C30.1106 12.3128 30.0459 12.469 29.9308 12.5842C29.8156 12.6994 29.6594 12.7641 29.4965 12.7641Z" fill="#A8A8A8" />
            <path d="M29.4965 17.418H19.848C19.6852 17.418 19.5289 17.3533 19.4138 17.2381C19.2986 17.123 19.2339 16.9667 19.2339 16.8039C19.2339 16.641 19.2986 16.4848 19.4138 16.3696C19.5289 16.2544 19.6852 16.1897 19.848 16.1897H29.4965C29.6594 16.1897 29.8156 16.2544 29.9308 16.3696C30.0459 16.4848 30.1106 16.641 30.1106 16.8039C30.1106 16.9667 30.0459 17.123 29.9308 17.2381C29.8156 17.3533 29.6594 17.418 29.4965 17.418Z" fill="#A8A8A8" />
            <path d="M29.4965 22.0718H19.848C19.6852 22.0718 19.5289 22.0071 19.4138 21.8919C19.2986 21.7768 19.2339 21.6206 19.2339 21.4577C19.2339 21.2948 19.2986 21.1386 19.4138 21.0234C19.5289 20.9082 19.6852 20.8435 19.848 20.8435H29.4965C29.6594 20.8435 29.8156 20.9082 29.9308 21.0234C30.0459 21.1386 30.1106 21.2948 30.1106 21.4577C30.1106 21.6206 30.0459 21.7768 29.9308 21.8919C29.8156 22.0071 29.6594 22.0718 29.4965 22.0718Z" fill="#A8A8A8" />
            <path d="M8.34527 17.5223C7.94636 17.5223 7.55642 17.404 7.22474 17.1824C6.89306 16.9608 6.63455 16.6458 6.4819 16.2773C6.32924 15.9087 6.2893 15.5032 6.36712 15.1119C6.44495 14.7207 6.63704 14.3613 6.91911 14.0793C7.20117 13.7972 7.56055 13.6051 7.95179 13.5273C8.34303 13.4495 8.74856 13.4894 9.1171 13.6421C9.48564 13.7947 9.80064 14.0532 10.0223 14.3849C10.2439 14.7166 10.3622 15.1065 10.3622 15.5054C10.3618 16.0402 10.1492 16.5531 9.77107 16.9312C9.3929 17.3094 8.88008 17.522 8.34527 17.5223ZM8.34527 14.7181C8.18925 14.7181 8.03674 14.7644 7.90703 14.8511C7.77732 14.9378 7.67625 15.061 7.6166 15.2052C7.55695 15.3493 7.5414 15.5079 7.57193 15.661C7.60246 15.814 7.67769 15.9545 7.78809 16.0647C7.8985 16.1749 8.03913 16.2499 8.19218 16.2802C8.34523 16.3105 8.50383 16.2947 8.6479 16.2349C8.79197 16.175 8.91504 16.0737 9.00155 15.9439C9.08805 15.814 9.13409 15.6614 9.13385 15.5054C9.13352 15.2965 9.0503 15.0962 8.90245 14.9486C8.75459 14.801 8.5542 14.7181 8.34527 14.7181Z" fill="#A8A8A8" />
            <path d="M10.6666 22.072H6.0248C5.86192 22.072 5.7057 22.0073 5.59053 21.8921C5.47535 21.777 5.41064 21.6207 5.41064 21.4579V20.5772C5.41064 18.8219 6.30118 17.4462 7.43983 17.4462H9.24423C10.3866 17.4462 11.2808 18.8219 11.2808 20.5772V21.4554C11.2811 21.5363 11.2655 21.6164 11.2347 21.6912C11.204 21.766 11.1588 21.834 11.1018 21.8913C11.0447 21.9486 10.9769 21.994 10.9022 22.025C10.8275 22.056 10.7475 22.072 10.6666 22.072ZM6.63896 20.8437H10.0525V20.5772C10.0525 19.3967 9.5292 18.6745 9.24423 18.6745H7.43983C7.15731 18.6745 6.63896 19.3967 6.63896 20.5772V20.8437Z" fill="#A8A8A8" />
        </svg>
    )
}

export default Badge