import { Box, Card, CardContent, CardMedia, Typography, useTheme } from '@mui/material'
import React from 'react'
import CustomCheckBox from './CustomCheckBox'
import CustomBtn from './CustomBtn';
import ArrowRight from '../Assets/svg/ArrowRight';
import { useNavigate } from 'react-router-dom';
import { url } from '../utils/Host';

const BookingCard = ({ showActionBtn = false, hideData = false, item, onClick }) => {
    const navigate = useNavigate();
    const { palette: { primary } } = useTheme();
    return (
        <>
            <Card
                onClick={onClick}
                sx={{
                    cursor: 'pointer',
                    borderRadius: '5px',
                    background: '#FDFDFD',
                    boxShadow: '0px 2px 6px 1px rgba(0, 0, 0, 0.10)'
                }}
            >
                <Box
                    sx={{
                        position: 'relative'
                    }}
                >
                    <CardMedia
                        sx={{ height: 200 }}
                        image={`${url}/picAdmin/${item?.image}`}
                        title={item?.sportName}
                    />
                    <Box
                        sx={{
                            borderRadius: '0px 0px 5px 5px',
                            background: 'rgba(0, 0, 0, 0.25)',
                            p: { xl: '15px', md: '10px', xs: '5px' },
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0
                        }}
                    >
                        <Typography
                            component="div"
                            sx={{
                                whiteSpace: 'nowrap',
                                textAlign: 'center',
                                color: primary.main,
                                fontSize: { xl: 22, md: 18, xs: 16 },
                                fontWeight: 700,
                                textShadow: '-2px 2px black'
                            }}
                        >
                            {item?.sportName}
                        </Typography>
                    </Box>
                </Box>
                <CardContent
                    sx={{
                        p: { xl: '16px', xs: '12px' }
                    }}
                >
                    <Typography component="div" textAlign={'center'} color={'#444'} fontSize={{ lg: 18, sm: 16, xs: 14 }} fontWeight={700}>
                        {item?.facilityName ?? ''}
                    </Typography>
                    <Typography color="#707070" textAlign={'center'} fontSize={{ sm: 14, xs: 12 }} fontWeight={500}>
                        {item?.facilityAddress ?? ''}
                    </Typography>
                    {!hideData &&
                        <>
                            {item?.facilitiesDetails?.length > 0 &&
                                <Typography color="#15192C" textAlign={'center'} fontSize={{ xl: 20, md: 18, xs: 16 }} fontWeight={500} mt={{ xl: '20px', md: '14px', xs: '10px' }}>
                                    Service - {item?.categories[0]?.Category}
                                </Typography>
                            }
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    mt: { sm: '10px', xs: '5px' }
                                }}
                            >
                                {item?.amenities?.length > 0 &&
                                    item?.amenities?.map(x => (
                                        <Box
                                            component={'span'}
                                            key={x?.name}
                                        >

                                            <CustomCheckBox
                                                label={x?.name}
                                                checked={true}
                                                checkBoxStyle={{
                                                    'span': {
                                                        fontSize: { sm: 14, xs: 12 },
                                                        p: '5px',
                                                        color: '#707070'
                                                    },
                                                    'svg': {
                                                        width: '20px',
                                                        height: '20px'
                                                    }
                                                }}
                                            />
                                        </Box>
                                    ))
                                }
                            </Box>
                        </>
                    }
                </CardContent>
                {!hideData &&
                    (showActionBtn ?
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Box
                                sx={{
                                    borderRadius: '0px 0px 0px 5px',
                                    background: 'rgba(89, 190, 244, 0.10)',
                                    p: { lg: '8px 10px', xs: '6px 0' },
                                    width: '100%',
                                    '&:hover': {
                                        backgroundColor: primary.darkBlue,
                                        '& p': {
                                            color: primary.main
                                        }
                                    }
                                }}
                            >
                                <Typography color={primary.darkBlue} textAlign={'center'} fontSize={{ lg: 14, xs: 12 }} fontWeight={500} >
                                    Booking Price
                                </Typography>
                                <Typography color={primary.darkBlue} textAlign={'center'} fontSize={{ lg: 18, xs: 14 }} fontWeight={600}>
                                    QR{(item?.Price)?.toFixed(2)}
                                </Typography>
                            </Box>
                            <CustomBtn
                                title='Book Now'
                                endIcon={<ArrowRight />}
                                onClick={() => navigate(`/service/${item?._id}`)}
                                btnStyle={{
                                    fontSize: { lg: 18, xs: 16 },
                                    width: '100%',
                                    textTransform: 'capitalize',
                                    height: '100%',
                                    pt: { lg: '10px', xs: '3px' },
                                    pb: { lg: '10px', xs: '4px' },
                                    '& svg': {
                                        width: '35px'
                                    }
                                }}
                            />
                        </Box>
                        :
                        <Box
                            sx={{
                                borderRadius: '0px 0px 5px 5px',
                                background: primary.darkBlue,
                                p: { xl: '15px 20px', xs: '10px' },
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography color={primary.main} textAlign={'center'} fontSize={{ xl: 20, xs: 18 }} fontWeight={500} >
                                Booking Price
                            </Typography>
                            <Typography color={primary.main} textAlign={'center'} fontSize={{ xl: 22, xs: 20 }} fontWeight={600}>
                                QR{(item?.Price)?.toFixed(2)}
                            </Typography>
                        </Box>)
                }
            </Card>
        </>
    )
}

export default BookingCard