import { Get, GetWithoutAuth, Post, Put } from "../utils/RequestHandler";

export const addNewCustomer = (data) => Post('/customer/addNewCustomer', data);

export const getFacilities = (category = '', city = '', limit = 6) => Get(`/customer/getFacilities?category=${category}&city=${city}&limit=${limit}`);

export const searchListing = (data) => Post('/customer/searchListing', data);

export const bookingDetails = (bookingId, userId) => Get(`/customer/bookingDetails/${bookingId}?client_id=${userId}`);

export const getPreviousBooking = (name = '', city = '', limit) => Get(`/customer/getPerviousBooking?name=${name}&city=${city}${limit ? `&limit=${limit}` : ''}`);

export const getUpcommingBookings = () => Get(`/customer/getUpcommingBooking`);

export const getAllCategories = () => Get(`/customer/allCategories`);

export const getSportDetailsByName = (category = '') => Get(`/admin/fetchSportsDataByName?category=${category}`);

export const confirmOrder = (data) => Post(`/customer/order_confirmation`, data);

export const cancelBooking = (id, data) => Post('/customer/bookingCancellation/'+ id, data);

export const applyCoupon = (coupon) => Get(`/customer/getCouponInfo/${coupon}`);

export const contactUs = (data) => Post(`/customer/customer_contact_us`, data);

export const getFavoriteSports = () => Get(`/customer/favoriteSports`);

export const addRemoveFavoriteSport = (sportId) => Post(`/customer/addRemovefavoriteSports/${sportId}`);

export const saveVisitsOnWeek = (data) => Post(`/admin/visitsOnWeek`,data);

export const getGeolocation = (data) => GetWithoutAuth(`https://geolocation-db.com/json/`);

export const updateProfile = (data) => Put(`/customer/profile`, data);

export const changepassword = (data) => Put(`/customer/profile_changepassword`, data);