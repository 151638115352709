import { Avatar, Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import CustomBtn from '../Components/CustomBtn';
import { useLocation, useNavigate } from 'react-router';
import { url } from '../utils/Host';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import ViewSchedule from '../Components/Modal/ViewSchedule';
import { getDuration } from '../utils/globalFunctions';
import { cancelBooking } from '../Endpoints/UserEndpoints';
import SnackAlert from '../Components/SnackAlert';

const BookingDetails = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { palette: { primary } } = useTheme();
    const isSmall = useMediaQuery('(max-width:430px)');
    const isLarge = useMediaQuery('(max-width:1059px)');
    const isMedium = useMediaQuery('(max-width:680px)');
    const user = useSelector(state => state.auth.userData);

    const [type, setType] = useState();
    const [message, setMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const [showModal, setShowModal] = useState(false);

    const checkSelectedDay = (day) => Object.keys(state?.slots)?.some(x => dayjs(x).format('dd') === day);

    const handleCancelBooking = async () => {
        try {
            const Administrator = state?.facilitiesDetails?.Administrator
            const FinanceUser = state?.facilitiesDetails?.FinanceUser
            const Trainer = state?.serviceClassDetails?.Trainer
            let arr = []
            if (Administrator) {
                arr.push(Administrator)
            }
            if (FinanceUser) {
                arr.push(FinanceUser)
            }
            if (Trainer) {
                arr.push(Trainer)
            }
            const req = {
                facilityName: state?.facilitiesDetails?.Name,
                facilityId: state?.facilitiesDetails?._id,
            }
            await cancelBooking(state?._id, {
                ids: arr,
                details: req
            });

            setType('success');
            setMessage('Booking is cancelled successfully!');
            setShowAlert(true);

            setTimeout(() => {
                navigate('/bookings');
            }, 1000)
        } catch (e) {
            console.log(e);
            setType('error');
            setMessage('Unable to cancel the booking!');
            setShowAlert(true);
        }
    }

    return (
        <>
            {/* Banner */}
            <Box
                sx={{
                    background: `linear-gradient(0deg, rgb(23 130 202 / 0%) 0%, rgb(23 130 202 / 0%) 100%), url(${url}/picAdmin/${state?.categories?.ProfileImage}), lightgray 50% / cover no-repeat`,
                    width: '100%',
                    height: { md: '40vh', xs: isSmall ? '300px' : '400px' },
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
            >
                <Box
                    sx={{
                        py: isSmall ? '5px' : isLarge ? '10px' : '15px',
                        mt: 'auto',
                        width: '100%',
                        borderRadius: '0px 0px 5px 5px',
                        background: 'rgba(0, 0, 0, 0.25)',
                    }}
                >
                    <Typography
                        sx={{
                            color: primary.main,
                            fontFamily: 'Nunito Sans',
                            fontSize: { md: '40px', sm: '35px', xs: '22px' },
                            fontWeight: 700,
                            textAlign: 'center',
                        }}
                    >
                        View My Booking
                    </Typography>
                </Box>
            </Box>
            {/* Banner */}
            {/* BODY */}
            <Box
                sx={{
                    m: isSmall ? '15px' : isMedium ? '30px 25px' : isLarge ? '80px' : '100px 116px'
                }}
            >
                <Grid
                    container
                    sx={{
                        mt: isSmall ? 6 : 0,
                        borderRadius: '5px',
                        border: { md: '1px solid #E6E6E6', xs: 0 },
                        background: { md: '#FDFDFD', xs: primary.main },
                        boxShadow: { md: '0px 2px 6px 1px rgba(0, 0, 0, 0.10)', xs: 'none' },
                        p: { sm: '20px', xs: 0 }
                    }}
                >
                    <Grid
                        item
                        md={5}
                        xs={12}
                    >
                        <Box
                            sx={{
                                borderRadius: '5px',
                                border: { md: 0, xs: '1px solid #E6E6E6' },
                                background: '#FDFDFD',
                                boxShadow: { md: 'none', xs: '0px 2px 6px 1px rgba(0, 0, 0, 0.10)' },
                                p: { md: 0, xs: '20px' }
                            }}
                            component={'div'}
                        >
                            <Box
                                component={'div'}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: '30px',
                                }}
                            >
                                <Box
                                    component={'div'}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        gap: { sm: '20px', xs: '10px' }
                                    }}
                                >
                                    <Avatar
                                        alt="User"
                                        src={`${url}/profilePicCustomer/${user?.profilePic}`}
                                        sx={{
                                            width: { sm: 65, xs: 55 },
                                            height: { sm: 65, xs: 55 },
                                        }}
                                    />
                                    <Typography color={'#15192C'} fontSize={{ xs: 25 }} fontWeight={700}>
                                        {user?.firstName}
                                    </Typography>
                                </Box>
                                <Typography color={primary.darkBlue} fontSize={{ sm: 20, xs: 18 }} fontWeight={700}>
                                    ${Number(state?.paymentDetails?.TotalCost ?? 0).toFixed(2)}
                                </Typography>
                            </Box>
                            <Box
                                component={'div'}
                                sx={{
                                    display: 'flex',
                                    flexDirection: isLarge ? 'column' : 'row',
                                    alignItems: 'center',
                                    gap: isLarge ? '20px' : '30px',
                                    mt: '30px'
                                }}
                            >
                                <Input
                                    label={'Sports'}
                                    value={state?.categories?.Category}
                                />
                                <Input
                                    label={'Duration'}
                                    value={getDuration(state?.slots)}
                                />
                            </Box>
                            <Typography color={'#6E6F73'} fontSize={isLarge ? 16 : 18} fontWeight={500} textAlign={'center'} mt={isLarge ? '30px' : '50px'}>
                                Booked on: {dayjs(state?.BookingDate).format('DD MMM YYYY, h:mm A')}
                            </Typography>
                            {/* <Box
                                component={'div'}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '20px',
                                    mt: isLarge ? '35px' : '50px'
                                }}
                            >
                                <CustomBtn
                                    title='Cancel'
                                    btnStyle={{
                                        borderRadius: '18px',
                                        border: '1px solid #DBDBDB',
                                        color: '#C51B1B',
                                        fontSize: isLarge ? 14 : 16,
                                        p: '7px 50px',
                                        textTransform: 'capitalize',
                                        boxShadow: 'none',
                                        background: 'transparent',
                                        '&:hover': {
                                            background: '#C51B1B',
                                            borderColor: '#C51B1B',
                                            borderWidth: 1
                                        }
                                    }}
                                />
                                <CustomBtn
                                    title='Re-schedule'
                                    btnStyle={{
                                        borderRadius: '18px',
                                        textTransform: 'capitalize',
                                        border: `1px solid ${primary.darkBlue}`,
                                        color: primary.darkBlue,
                                        fontSize: isLarge ? 14 : 16,
                                        p: '7px 50px',
                                        boxShadow: 'none',
                                        background: 'transparent',
                                        '&:hover': {
                                            borderWidth: 1
                                        }
                                    }}
                                />
                            </Box>
                            <Typography color={'#6E6F73'} fontSize={isLarge ? 13 : 14} fontWeight={700} textAlign={'center'} mt={'30px'}>
                                Re-schedule & cancelation can be done until 6 Nov
                            </Typography> */}
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        sx={{
                            display: { md: 'block', xs: 'none' }
                        }}
                    >
                        <Box sx={{ background: '#E6E6E6', height: '100%', width: '2px', m: 'auto' }} />
                    </Grid>
                    <Grid
                        item
                        md={5}
                        xs={12}
                        mt={{ md: 0, sm: 6, xs: 3 }}
                    >
                        <Box
                            sx={{
                                borderRadius: '5px',
                                border: { md: 0, xs: '1px solid #E6E6E6' },
                                background: '#FDFDFD',
                                boxShadow: { md: 'none', xs: '0px 2px 6px 1px rgba(0, 0, 0, 0.10)' },
                                p: { md: 0, xs: '20px' }
                            }}
                            component={'div'}
                        >
                            <Typography color={'#15192C'} fontSize={isLarge ? 23 : 25} fontWeight={700}>
                                Session details
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: '30px',
                                    mt: '25px'
                                }}
                            >
                                <Typography color={'#6E6F73'} fontSize={isLarge ? 16 : 18} fontWeight={500}>
                                    Days
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px'
                                    }}
                                >
                                    <Typography color={checkSelectedDay('Mo') ? primary.darkBlue : '#9FA1A6'} fontSize={isLarge ? 14 : 16} fontWeight={600}>
                                        M
                                    </Typography>
                                    <Typography color={checkSelectedDay('Tu') ? primary.darkBlue : '#9FA1A6'} fontSize={isLarge ? 14 : 16} fontWeight={600}>
                                        T
                                    </Typography>
                                    <Typography color={checkSelectedDay('We') ? primary.darkBlue : '#9FA1A6'} fontSize={isLarge ? 14 : 16} fontWeight={600}>
                                        W
                                    </Typography>
                                    <Typography color={checkSelectedDay('Th') ? primary.darkBlue : '#9FA1A6'} fontSize={isLarge ? 14 : 16} fontWeight={600}>
                                        T
                                    </Typography>
                                    <Typography color={checkSelectedDay('Fr') ? primary.darkBlue : '#9FA1A6'} fontSize={isLarge ? 14 : 16} fontWeight={600}>
                                        F
                                    </Typography>
                                    <Typography color={checkSelectedDay('Sa') ? primary.darkBlue : '#9FA1A6'} fontSize={isLarge ? 14 : 16} fontWeight={600}>
                                        S
                                    </Typography>
                                    <Typography color={checkSelectedDay('Su') ? primary.darkBlue : '#9FA1A6'} fontSize={isLarge ? 14 : 16} fontWeight={600}>
                                        S
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: '30px',
                                    mt: '30px'
                                }}
                            >
                                <Typography color={'#6E6F73'} fontSize={isLarge ? 16 : 18} fontWeight={500}>
                                    Schedule
                                </Typography>
                                <Typography
                                    color={primary.darkBlue}
                                    fontSize={isLarge ? 14 : 16}
                                    fontWeight={600}
                                    onClick={() => setShowModal(true)}
                                    sx={{
                                        cursor: 'pointer'
                                    }}
                                >
                                    View Dates & time
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: '30px',
                                    mt: '30px'
                                }}
                            >
                                <Typography color={'#6E6F73'} fontSize={isLarge ? 16 : 18} fontWeight={500}>
                                    Gender
                                </Typography>
                                <Typography color={'#9FA1A6'} fontSize={isLarge ? 14 : 16} fontWeight={600} textTransform={'capitalize'}>
                                    {user?.gender}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: '30px',
                                    mt: '30px'
                                }}
                            >
                                <Typography color={'#6E6F73'} fontSize={isLarge ? 16 : 18} fontWeight={500}>
                                    Age
                                </Typography>
                                <Typography color={'#9FA1A6'} fontSize={isLarge ? 14 : 16} fontWeight={600}>
                                    {dayjs().diff(dayjs(user?.dob), 'years')} {dayjs().diff(dayjs(user?.dob), 'years') > 1 ? 'years' : 'year'}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: '30px',
                                    mt: '30px'
                                }}
                            >
                                <Typography color={'#6E6F73'} fontSize={isLarge ? 16 : 18} fontWeight={500}>
                                    Address
                                </Typography>
                                <Typography color={'#9FA1A6'} fontSize={isLarge ? 14 : 16} fontWeight={600}>
                                    {user?.billingAddress?.appartment}{user?.billingAddress?.street ? ", " + user?.billingAddress?.street : ""}{user?.billingAddress?.city ? ", " + user?.billingAddress?.city : ''}{user?.billingAddress?.country ? ", " + user?.billingAddress?.country : ''}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: { sm: '30px', xs: '15px' },
                        mt: isLarge ? '35px' : '50px'
                    }}
                    component={'div'}
                >
                    <CustomBtn
                        onClick={() => handleCancelBooking()}
                        btnStyle={{
                            background: '#000',
                            borderRadius: '25px',
                            textTransform: 'capitalize',
                            fontSize: isLarge ? { sm: 14, xs: 13 } : 16
                        }}
                        title='Delete Booking'
                    />
                    <CustomBtn
                        onClick={() => navigate('/venue')}
                        btnStyle={{
                            borderRadius: '25px',
                            textTransform: 'capitalize',
                            fontSize: isLarge ? { sm: 14, xs: 13 } : 16
                        }}
                        title='Add new booking'
                    />
                </Box>
            </Box>
            {/* BODY */}
            <ViewSchedule
                open={showModal}
                slots={state?.slots}
                handleClose={() => setShowModal(false)}
            />
            <SnackAlert
                type={type}
                open={showAlert}
                message={message}
                handleClose={() => setShowAlert(false)}
            />
        </>
    )
}

const Input = ({ label, value }) => {
    const isLarge = useMediaQuery('(max-width:1059px)');
    return (
        <Box
            component={'div'}
            sx={{
                width: '100%'
            }}
        >
            <Typography color={'#6E6F73'} fontSize={isLarge ? 14 : 16} fontWeight={700}>
                {label}
            </Typography>
            <Box
                sx={{
                    padding: '8px 12px',
                    borderRadius: '8px',
                    background: '#F4F4F4',
                    mt: '10px',
                }}
            >
                <Typography color={'#15192C'} fontSize={isLarge ? 14 : 16} fontWeight={500}>
                    {value}
                </Typography>
            </Box>
        </Box>
    )
}
export default BookingDetails