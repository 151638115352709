import { Navigate, Route, Routes, ScrollRestoration } from "react-router-dom";
import Home from "./Pages/Home";
import ServiceDetails from "./Pages/ServiceDetails";
import Layout from "./Components/Layout";
import Bookings from "./Pages/Bookings";
import BookingDetails from "./Pages/BookingDetails";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "./Redux/Slices/authSlice";
import VenueDetails from "./Pages/VenueDetails";
import Checkout from "./Pages/Checkout";
import Response from "./Pages/Response";
import Venues from "./Pages/Venues";
import Profile from "./Pages/Profile";

function App() {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem('User'));

  useEffect(() => {
    if (userData && userData.token) {
      dispatch(loginUser(userData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, window.location.pathname])

  return (
    <>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/service/:id" element={<ServiceDetails />} />
          <Route path="/venue/:id" element={<VenueDetails />} />
          <Route path="/venue/:id/:category" element={<VenueDetails />} />
          <Route path="/category/:category" element={<VenueDetails />} />
          <Route path="/bookings" element={<Bookings />} />
          <Route path="/bookings/:id" element={<BookingDetails />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/response" element={<Response />} />
          <Route path="/venues" element={<Venues />} />
          <Route path="/profile" element={<Profile />} />

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Layout>
      <ScrollRestoration />
    </>
  );
}

export default App;
