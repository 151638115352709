import { Checkbox, FormControlLabel, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import CheckedIcon from '../Assets/svg/CheckedIcon';

const CustomCheckBox = ({ checked, onChange, name, label, disabled = false, checkBoxStyle }) => {
    const { palette: { primary } } = useTheme();
    const isSmall = useMediaQuery('(max-width:430px)');
    return (
        <FormControlLabel
            sx={[{
                '.Mui-checked': {
                    color: `${primary.lightBlue} !important`
                },
                'span': {
                    fontSize: isSmall ? 14 : 16,
                    p: isSmall ? '5px' : '9px'
                },
                'svg': {
                    fontSize:23
                }
            }, checkBoxStyle]}
            onChange={onChange}
            control={<Checkbox
                checkedIcon={<CheckedIcon />}
                icon={<CheckedIcon  checked={false}/>}
                disabled={disabled}
                name={name}
                checked={checked}
            />}
            label={label}
        />
    )
}

export default CustomCheckBox