import { Box, FormControl, FormControlLabel, InputLabel, Radio, RadioGroup, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'

const CustomRadioGroup = ({ options = [], value = null, name, onChange, radioStyle, isSubmitted = false, required = false, label }) => {
    const { palette: { primary } } = useTheme();
    const isSmall = useMediaQuery('(max-width:430px)');

    const [error, setError] = useState(false);
    const [message, setMessage] = useState(false);

    useEffect(() => {
        if (isSubmitted && required) {
            if (!value) {
                setError(true);
                setMessage(`${label} is required!`);
            } else {
                setError(false);
                setMessage('');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitted, required, value])

    return (
        <Box
            component={'div'}
        >
            {label &&
                <InputLabel
                    sx={{
                        color: error ? 'rgb(211, 47, 47)' : '#222',
                        fontSize: '16px',
                        fontWeight: 600,
                        mb: '10px'
                    }}
                    required={required}
                >
                    {label}
                </InputLabel>
            }
            <FormControl>
                <RadioGroup
                    row
                    name={name}
                    value={value}
                    onChange={onChange}
                    sx={[{
                        '.Mui-checked': {
                            color: `${primary.lightBlue} !important`
                        },
                        'span': {
                            fontSize: { sm: 16, xs: 12 },
                        },
                        '.MuiRadio-colorPrimary': {
                            p: isSmall ? '5px' : '9px'
                        },
                        'svg': {
                            width: {sm: '1em',xs:'20px'} ,
                            height: {sm: '1em',xs:'20px'} ,
                        }
                    }, radioStyle]}
                >
                    {options.map(x => <FormControlLabel key={x.value} value={x.value} control={<Radio />} label={x.label} />)}

                </RadioGroup>
                {message && <Typography color={'rgb(211, 47, 47)'} fontSize={12}>{message}</Typography>}
            </FormControl>
        </Box>
    )
}

export default CustomRadioGroup