import { Close } from '@mui/icons-material'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useReducer, useState } from 'react'
import TimeSlot from '../TimeSlot'
import Calendar from '../Calendar'
import dayjs from 'dayjs'
import { formReducer } from '../../utils/globalFunctions'

const ViewSchedule = ({ open = false, handleClose, slots }) => {
    const isSmall = useMediaQuery('(max-width:430px)');

    const [selectedSlots, setSelectedSlots] = useState([]);
    const [formData, setFormData] = useReducer(formReducer, {});

    useEffect(() => {
        if (open) {
            let items = [
                {
                    target: {
                        name: 'selectedDate',
                        value: dayjs(Object.keys(slots)[0]).format('MM/DD/YYYY')
                    }
                },
            ];
            items.forEach(x => setFormData(x));

            let temp = {};
            if (slots) {
                Object.keys(slots)?.forEach(slot => {
                    temp[dayjs(slot).format('MM/DD/YYYY')] = slots[slot]
                })
            }
            setSelectedSlots(temp);
        }
    }, [open, slots]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            sx={{
                '& .MuiPaper-root': {
                    width: isSmall ? '100%' : 'auto',
                    m: { sm: '32px', xs: '16px' },
                    maxWidth: { sm: '600px', xs: '610px' }
                }
            }}
        >
            <Box
                component={'div'}
                sx={{
                    gap: '20px',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <DialogTitle>
                    Selected Timeslots
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                        p: '13px 10px'
                    }}
                >
                    <Close />
                </IconButton>
            </Box>
            <DialogContent
                sx={{
                    p: { sm: '20px 24px', xs: '20px 10px' }
                }}
            >
                <Calendar
                    readOnly={true}
                    selectedSlots={selectedSlots}
                    availableSlots={[]}
                    value={dayjs(formData['selectedDate'])}
                    slots={[]}
                    onChange={(val) => {
                        setFormData({
                            target: {
                                name: 'selectedDate',
                                value: val
                            }
                        })
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: isSmall ? '10px' : '20px',
                        flexWrap: 'wrap',
                        mt: 4
                    }}
                >
                    {selectedSlots[formData['selectedDate']] ?
                        selectedSlots[formData['selectedDate']]?.map(slot => (
                            <TimeSlot
                                item={slot}
                                isSelected={true}
                                handleSelect={() => { }}
                            />
                        ))
                        :
                        <Typography>
                            No Timeslots selected, please check other dates.
                        </Typography>
                    }
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ViewSchedule