import { Box, Button, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import usePagination from '@mui/material/usePagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const List = styled('ul')({
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex'
});

const Pagination = (props) => {
    const { palette: { primary } } = useTheme();
    const { items } = usePagination({
        count: props?.count ?? 0,
        onChange(event, page) {
            props.handleChangePage(page);
        },
    });

    return (
        <nav style={{ width: '100%' }}>
            <List sx={{ justifyContent: 'space-between' }}>
                {items
                    .filter(({ type }) => type === 'previous')
                    .map(({ type, ...item }, index) => (
                        <li key={index}>
                            <Button
                                {...item}
                                variant="contained"
                                sx={{
                                    background: primary.darkBlue,
                                    textTransform: 'capitalize',
                                    color: primary.main,
                                    boxShadow: 'none',
                                    fontSize: { sm: 14, xs: 12 },
                                    p: { sm: '6px 16px', xs: '5px 12px' }
                                }}
                                startIcon={<ArrowBackIcon />}
                            >
                                {type}
                            </Button>
                        </li>
                    ))}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '14px'
                    }}
                >
                    {items
                        .map(({ page, type, selected, ...item }, index) => {
                            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                                return <li key={index}>...</li>
                            } else if (type === 'page') {
                                return (
                                    <li key={index}>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                fontWeight: selected
                                                    ? 'bold'
                                                    : undefined,
                                                background: selected
                                                    ? primary.darkBlue
                                                    : 'white',
                                                minWidth: { sm: '30px', xs: '25px' },
                                                padding: { sm: '1px 0px', xs: 0 },
                                                borderRadius: '100px',
                                                color: selected ? '#EDEDED' : '#808080',
                                                boxShadow: 'none',
                                                ':hover': {
                                                    color: primary.main,
                                                    background: primary.darkBlue
                                                }
                                            }}
                                            {...item}
                                        >
                                            {page}
                                        </Button>
                                    </li>
                                )
                            }
                            return null;
                        })}
                </Box>              
                {items
                    .filter(({ type }) => type === 'next')
                    .map(({ type, ...item }, index) => (
                        <li key={index}>
                            <Button
                                {...item}
                                variant="contained"
                                sx={{
                                    background: primary.darkBlue,
                                    textTransform: 'capitalize',
                                    color: primary.main,
                                    boxShadow: 'none',
                                    fontSize: { sm: 14, xs: 12 },
                                    p: { sm: '6px 16px', xs: '5px 12px' }
                                }}
                                endIcon={<ArrowForwardIcon />}
                            >
                                {type}
                            </Button>
                        </li>
                    ))}
            </List>
        </nav>
    );
};

export default Pagination;