import { Box, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'

const StandardField = ({ type = 'text', label = '', name = '', value = '', onChange = () => { }, inputStyle, placeholder = '', InputProps, disabled = false, isSubmitted = false, required = false }) => {
    const { palette: { primary } } = useTheme();

    const [error, setError] = useState(false);
    const [message, setMessage] = useState(false);

    useEffect(() => {
        if (isSubmitted && required) {
            if (!value) {
                setError(true);
                setMessage(`${placeholder} is required!`);
            } else {
                setError(false);
                setMessage('');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitted, required, value])

    return (
        <Box
            component={'div'}
            width={'100%'}
        >
            <TextField
                disabled={disabled}
                variant='standard'
                name={name}
                value={value}
                onChange={onChange}
                type={type}
                placeholder={placeholder}
                sx={[{
                    width: '100%',
                    '.MuiInputBase-root': {
                        fontSize: { lg: 16, xs: 14 },
                    },
                    '.MuiInputBase-root::before': {
                        borderBottomColor: error ? 'rgb(211, 47, 47)' : 'rgba(0, 0, 0, 0.42)'
                    },
                    '.MuiInputBase-root::after': {
                        borderBottomColor: error ? 'rgb(211, 47, 47)' : primary.darkBlue
                    }
                }, inputStyle]}
                InputProps={InputProps}
            />
            {message && <Typography color={'rgb(211, 47, 47)'} fontSize={12} >{message}</Typography>}
        </Box>
    )
}

export default StandardField