import React from 'react'

const ArrowRight = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 55 54" fill="none">
            <rect width="38.6245" height="38.7477" rx="19.3122" transform="matrix(-0.649693 0.760197 -0.771811 -0.635852 55 24.6378)" fill="white" fillOpacity="0.3" />
            <path d="M21.9913 36.2374C22.1354 36.3791 22.3066 36.4915 22.495 36.5681C22.6833 36.6448 22.8853 36.6843 23.0892 36.6843C23.2931 36.6843 23.495 36.6448 23.6834 36.5681C23.8718 36.4915 24.0429 36.3791 24.187 36.2374L32.2378 28.3305C32.5284 28.0448 32.6916 27.6576 32.6916 27.2538C32.6916 26.8501 32.5284 26.4629 32.2378 26.1772L24.1844 18.2702C23.8928 17.9882 23.4995 17.8307 23.0901 17.8321C22.6808 17.8335 22.2885 17.9937 21.9989 18.2777C21.7093 18.5617 21.5457 18.9466 21.5438 19.3485C21.5419 19.7504 21.7018 20.1368 21.9887 20.4235L28.9482 27.2538L21.9913 34.0842C21.7007 34.3699 21.5375 34.7571 21.5375 35.1608C21.5375 35.5646 21.7007 35.9518 21.9913 36.2374Z" fill="white" />
        </svg>
    )
}

export default ArrowRight