import { Box, InputBase, InputLabel, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { validateEmail, validatePassword } from '../utils/globalFunctions';

const InputField = ({ type = 'text', label = '', name = '', value = '', onChange = () => { }, required = false, multiline = false, minRows = 1, labelStyle, inputStyle, placeholder, endAdornment = null, startAdornment = null, isSubmitted = false }) => {

    const { palette: { primary } } = useTheme();

    const [error, setError] = useState(false);
    const [message, setMessage] = useState(false);

    useEffect(() => {
        if (isSubmitted && required) {
            if (!value) {
                setError(true);
                setMessage(`${placeholder ?? label} is required!`);
            } else {
                if (type === 'email') {
                    if (validateEmail(value)) {
                        setError(false);
                        setMessage('');
                    } else {
                        setError(true);
                        setMessage(`Please enter a valid email!`);
                    }
                    return;
                }
                if (type === 'password') {
                    if (validatePassword(value)) {
                        setError(false);
                        setMessage('');
                    } else {
                        setError(true);
                        setMessage(`Password must have atleast 8-16 characters, should contain 1 uppercase, 1 lowercase!`);
                    }
                    return;
                }
                if (type === 'phone') {
                    if (String(value)?.length === 8) {
                        setError(false);
                        setMessage('');
                    } else {
                        setError(true);
                        setMessage(`Phone number must be of 8 digits!`);
                    }
                    return;
                }
                setError(false);
                setMessage('');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitted, required, value])

    return (
        <Box
            sx={{
                '.Mui-focused': {
                    backgroundColor: '#F6FCFF',
                    border: `1px solid ${error ? 'rgb(211, 47, 47)' : primary.darkBlue}`,
                }
            }}
            component={'div'}
        >
            <InputLabel
                required={required}
                sx={[{
                    color: error ? 'rgb(211, 47, 47)' : '#222',
                    fontSize: '16px',
                    fontWeight: 600
                }, labelStyle]}
            >
                {label}
            </InputLabel>
            <InputBase
                minRows={minRows}
                multiline={multiline}
                name={name}
                value={value}
                onChange={(e) => {
                    if (type === 'phone') {
                        if (e.target.value.length <= 8) {
                            onChange(e);
                        }
                    } else {
                        onChange(e);
                    }
                }}
                type={type}
                placeholder={placeholder}
                startAdornment={startAdornment}
                endAdornment={endAdornment}
                sx={[{
                    background: '#F6F6F6',
                    fontFamily: "Nunito Sans",
                    padding: '10px 20px',
                    width: '100%',
                    mt: '10px',
                    border: error ? '1px solid rgb(211, 47, 47)' : '1px solid #F6F6F6'
                }, inputStyle]}
            />
            {message && <Typography color={'rgb(211, 47, 47)'} fontSize={12}>{message}</Typography>}
        </Box>
    )
}

export default InputField