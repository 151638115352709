import React, { useEffect, useReducer, useRef, useState } from 'react'
import InputField from '../Components/InputField'
import { Avatar, Box, Divider, Grid, IconButton, InputAdornment, Typography, useMediaQuery, useTheme } from '@mui/material';
import FormUser from '../Assets/svg/FormUser';
import { formReducer, validateEmail, validatePassword } from '../utils/globalFunctions';
import Lock from '../Assets/svg/Lock';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CustomBtn from '../Components/CustomBtn';
import Placeholder from '../Assets/svg/Placeholder';
import CustomRadioGroup from '../Components/CustomRadioGroup';
import CustomDatePicker from '../Components/CustomDatePicker';
import SnackAlert from '../Components/SnackAlert';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { url } from '../utils/Host';
import { changepassword, updateProfile } from '../Endpoints/UserEndpoints';
import { loginUser } from '../Redux/Slices/authSlice';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
    const imageRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { palette: { primary } } = useTheme();
    const isLarge = useMediaQuery('(max-width:1059px)');
    const isMedium = useMediaQuery('(max-width:680px)');
    const isSmall = useMediaQuery('(max-width:430px)');

    const [type, setType] = useState();
    const [image, setImage] = useState(null);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const user = useSelector(state => state.auth.userData);
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [formData, setFormData] = useReducer(formReducer, {});
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [hasChangedPassword, setHasChangedPassword] = useState(false);

    const handleImage = (e) => {
        const image = e.target.files[0];
        setFormData({
            target: {
                name: "profilePic",
                value: image
            }
        })
        setImage(URL.createObjectURL(image))
    }

    const removeImage = () => {
        setFormData({
            target: {
                name: "profilePic",
                value: null
            }
        })
        setImage(null)
    }

    const handleUpdate = async () => {
        setIsSubmitted(true);
        const hasChanged = (formData['oldPassword'] || formData['password'] || formData['cpassword']) ? true : false;
        setHasChangedPassword(hasChanged);
        if ((validateEmail(formData['email']) && formData['firstName'] && formData['lastName'] && formData['phone']) && ((validatePassword(formData['oldPassword']) && validatePassword(formData['password'])) || !hasChanged)) {
            if (formData['password'] === formData['cPassword'] || !hasChanged) {
                try {
                    const data = new FormData();
                    data.append('dob', formData.dob);
                    data.append('email', formData.email);
                    data.append('phone', formData.phone);
                    data.append('gender', formData.gender);
                    data.append('lastName', formData.lastName);
                    data.append('firstName', formData.firstName);
                    data.append('profilePic', formData.profilePic);
                    data.append('termCondition', user.termCondition);

                    const response = await updateProfile(data);
                    if (response?.message === "Profile updated!") {
                        const updatedData = {
                            token: user?.token,
                            ...response?.data
                        }
                        if (formData['password']) {
                            const data = {
                                changePassword: true,
                                confirmPass: formData['cPassword'],
                                currentPass: formData['oldPassword'],
                                newPass: formData['password']
                            }
                            try {
                                await changepassword(data);
                                setLoading(false);
                                setType('success');
                                setMessage("Profile updated successfully!");
                                setIsSubmitted(false);
                                setShowAlert(true);
                            } catch (e) {
                                setType('error');
                                setMessage('Error occured please try again!')
                                setShowAlert(true);
                                setLoading(false);
                            }

                        } else {
                            setLoading(false);
                            setIsSubmitted(false);
                            setType('success');
                            setMessage("Profile updated successfully!");
                            setShowAlert(true);
                        }
                        dispatch(loginUser(updatedData));
                        localStorage.setItem('User', JSON.stringify(updatedData));
                    } else {
                        setType('error');
                        setMessage(response?.data ?? 'Error occured please try again!')
                        setShowAlert(true);
                        setLoading(false);
                    }
                } catch (e) {
                    console.log("ERR", e)
                    setType('error');
                    setMessage('Error occured please try again!')
                    setShowAlert(true);
                    setLoading(false);
                }
            } else {
                setType('error');
                setMessage("Password and Confirm Password didn't match!");
                setShowAlert(true);
            }
        }
    }

    useEffect(() => {
        if (user) {
            const items = [
                {
                    target: {
                        name: 'firstName',
                        value: user?.firstName
                    }
                },
                {
                    target: {
                        name: 'lastName',
                        value: user?.lastName
                    }
                },
                {
                    target: {
                        name: 'email',
                        value: user?.email
                    }
                },
                {
                    target: {
                        name: 'dob',
                        value: dayjs(user?.dob)
                    }
                },
                {
                    target: {
                        name: 'gender',
                        value: user?.gender
                    }
                },
                {
                    target: {
                        name: 'phone',
                        value: user?.phone
                    }
                },
                {
                    target: {
                        name: "profilePic",
                        value: user?.profilePic && user?.profilePic !== 'null' ? user?.profilePic : null
                    }
                }
            ];
            items.forEach(x => setFormData(x));
            if (user?.profilePic && user?.profilePic !== 'null') {
                setImage(`${url}/profilePicCustomer/${user?.profilePic}`)
            }
        }
    }, [user])

    if (!user) {
        navigate('/login');
        return;
    }

    return (
        <>
            <Box
                sx={{
                    m: isSmall ? '15px' : isMedium ? '30px 25px' : isLarge ? '50px' : { xl: '100px 170px', xs: '100px' }
                }}
            >
                <Grid container spacing={{ lg: 5, sm: 3, xs: 1 }} alignItems={'center'}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: { md: 'none', xs: 'block' }
                        }}
                    >
                        <Box
                            component={'div'}
                            sx={{
                                textAlign: 'center',
                                '& img': {
                                    width: '-webkit-fill-available',
                                    height: 'auto',
                                    maxWidth: '400px'
                                }
                            }}
                        >
                            <img
                                src='/images/signup.png'
                                alt='login'
                            />
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box
                            component={'span'}
                        >
                            <Typography color={'#1C1C1C'} fontWeight={700} fontSize={{ md: 40, sm: 30, xs: 20 }}>
                                Update Profile
                            </Typography>
                        </Box>
                        <Box
                            component={'div'}
                            sx={{
                                mt: '20px',
                                display: 'flex',
                                gap: { sm: '15px', xs: '5px' }
                            }}
                        >
                            {image ?
                                <Avatar
                                    src={image}
                                    sx={{
                                        bgcolor: primary.main,
                                        width: { sm: '80px', xs: '60px' },
                                        height: { sm: '80px', xs: '60px' }
                                    }}
                                    alt="Remy Sharp"
                                />
                                :
                                <Avatar
                                    sx={{
                                        bgcolor: primary.main,
                                        width: { sm: '80px', xs: '60px' },
                                        height: { sm: '80px', xs: '60px' }
                                    }}
                                    alt="Remy Sharp"
                                >
                                    <Placeholder />
                                </Avatar>
                            }
                            <Box
                                component={'div'}
                                sx={{
                                    mt: { sm: '10px', xs: '5px' }
                                }}
                            >
                                <Typography color={'#1C1C1C'} fontSize={{ sm: 18, xs: 14 }} fontWeight={{ sm: 400, xs: 600 }}>
                                    Profile picture
                                </Typography>
                                <Box
                                    component={'div'}
                                    sx={{
                                        mt: '2px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: { sm: '20px', xs: '10px' }
                                    }}
                                >
                                    <CustomBtn
                                        title='Upload Image'
                                        onClick={() => imageRef.current.click()}
                                        btnStyle={{
                                            color: '#23252D',
                                            fontWeight: 600,
                                            textTransform: 'capitalize',
                                            border: '1px solid #59BEF4',
                                            background: '#E0F4FF',
                                            p: { sm: '3px 19px', xs: '0px 10px' },
                                            boxShadow: 'none',
                                            fontSize: { sm: 14, xs: 12 },
                                            '&:hover': {
                                                borderWidth: 1
                                            }
                                        }}
                                    />
                                    <input
                                        type='file'
                                        ref={imageRef}
                                        accept="image/*"
                                        onChange={handleImage}
                                        style={{ display: 'none' }}
                                    />
                                    <CustomBtn
                                        onClick={removeImage}
                                        title='Remove Image'
                                        btnStyle={{
                                            color: '#CA0F2C',
                                            fontWeight: 600,
                                            textTransform: 'capitalize',
                                            border: '1px solid #FFD6DC',
                                            background: '#FFD6DC',
                                            p: { sm: '3px 19px', xs: '0px 10px' },
                                            boxShadow: 'none',
                                            fontSize: { sm: 14, xs: 12 },
                                            '&:hover': {
                                                background: '#CA0F2C',
                                                color: '#FFD6DC',
                                                borderColor: '#CA0F2C',
                                                borderWidth: 1
                                            }
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            mt={'30px'}
                            component={'div'}
                        >
                            <Grid container spacing={{ lg: 3, sm: 1, xs: 2 }}>
                                <Grid item sm={6} xs={12}>
                                    <InputField
                                        name='firstName'
                                        label='First Name'
                                        value={formData['firstName']}
                                        isSubmitted={isSubmitted}
                                        placeholder='First Name'
                                        required={true}
                                        onChange={setFormData}
                                        inputStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                            '& input': {
                                                pb: 0,
                                            },
                                            'svg': {
                                                minWidth: 20
                                            }
                                        }}
                                        labelStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" >
                                                <FormUser />
                                            </InputAdornment>
                                        }

                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <InputField
                                        name='lastName'
                                        label='Last Name'
                                        isSubmitted={isSubmitted}
                                        value={formData['lastName']}
                                        placeholder='Last Name'
                                        required={true}
                                        onChange={setFormData}
                                        inputStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                            '& input': {
                                                pb: 0,
                                            },
                                            'svg': {
                                                minWidth: 20
                                            }
                                        }}
                                        labelStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" >
                                                <FormUser />
                                            </InputAdornment>
                                        }

                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <InputField
                                        type='email'
                                        name='email'
                                        label='Email Address'
                                        isSubmitted={isSubmitted}
                                        value={formData['email']}
                                        placeholder='Email Address'
                                        required={true}
                                        onChange={setFormData}
                                        inputStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                            '& input': {
                                                pb: 0,
                                            },
                                        }}
                                        labelStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <InputField
                                        type='phone'
                                        name='phone'
                                        label='Phone Number'
                                        value={formData['phone']}
                                        isSubmitted={isSubmitted}
                                        placeholder='Phone Number'
                                        required={true}
                                        onChange={setFormData}
                                        inputStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                            '& input': {
                                                pb: 0,
                                            },
                                        }}
                                        labelStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomRadioGroup
                                        name='gender'
                                        value={formData['gender']}
                                        label='Gender'
                                        onChange={setFormData}
                                        options={[{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }]}
                                        radioStyle={{
                                            'span': {
                                                fontSize: { lg: 16, xs: 14 },
                                            },
                                            '.MuiRadio-colorPrimary': {
                                                p: { lg: '9px', xs: '5px' }
                                            },
                                            'svg': {
                                                width: { lg: '1em', xs: '20px' },
                                                height: { lg: '1em', xs: '20px' }
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomDatePicker
                                        name='dob'
                                        label='Date of Birth'
                                        value={formData['dob']}
                                        placeholder='Date of Birth'
                                        onChange={setFormData}
                                        inputStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                            '& input': {
                                                p: '13px 14px',
                                            },
                                        }}
                                        labelStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{ my: 2 }} />
                                </Grid>
                                <Typography
                                    mb={1}
                                    color={'#000'}
                                    fontWeight={700}
                                    fontSize={{ md: 20, sm: 16, xs: 14 }}
                                >
                                    Update Password
                                </Typography>
                                <Grid item xs={12}>
                                    <InputField
                                        type={showOldPassword ? 'text' : 'password'}
                                        name='oldPassword'
                                        label='Old Password'
                                        isSubmitted={hasChangedPassword}
                                        value={formData['oldPassword']}
                                        placeholder='Password'
                                        required={true}
                                        onChange={setFormData}
                                        labelStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                        }}
                                        inputStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                            '& input': {
                                                pb: 0,
                                            },
                                            'svg': {
                                                minWidth: 20
                                            }
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" >
                                                <Lock />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end" >
                                                <IconButton
                                                    sx={{
                                                        p: 0,
                                                        m: 0,
                                                    }}
                                                    onClick={() => setShowOldPassword((prev) => !prev)}
                                                >
                                                    {showOldPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <InputField
                                        type={showPassword ? 'text' : 'password'}
                                        name='password'
                                        label='Password'
                                        isSubmitted={hasChangedPassword}
                                        value={formData['password']}
                                        placeholder='Password'
                                        required={true}
                                        onChange={setFormData}
                                        labelStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                        }}
                                        inputStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                            '& input': {
                                                pb: 0,
                                            },
                                            'svg': {
                                                minWidth: 20
                                            }
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" >
                                                <Lock />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end" >
                                                <IconButton
                                                    sx={{
                                                        p: 0,
                                                        m: 0,
                                                    }}
                                                    onClick={() => setShowPassword((prev) => !prev)}
                                                >
                                                    {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <InputField
                                        type={showCPassword ? 'text' : 'password'}
                                        name='cPassword'
                                        label='Confirm Password'
                                        required={true}
                                        onChange={setFormData}
                                        isSubmitted={hasChangedPassword}
                                        value={formData['cPassword']}
                                        placeholder='Confirm Password'
                                        labelStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                        }}
                                        inputStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                            '& input': {
                                                pb: 0,
                                            },
                                            'svg': {
                                                minWidth: 20
                                            }
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" >
                                                <Lock />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end" >
                                                <IconButton
                                                    sx={{
                                                        p: 0,
                                                        m: 0,
                                                    }}
                                                    onClick={() => setShowCPassword((prev) => !prev)}
                                                >
                                                    {showCPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <CustomBtn
                                loading={loading}
                                onClick={handleUpdate}
                                title='Update Profile'
                                btnStyle={{
                                    textTransform: 'capitalize',
                                    mt: '40px',
                                    width: '100%',
                                    fontSize: { sm: 16, xs: 14 }
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        sx={{
                            display: { md: 'block', xs: 'none' }
                        }}
                    >
                        <img
                            src='/images/signup.png'
                            alt='login'
                            style={{
                                width: '-webkit-fill-available',
                                height: 'auto',
                                maxWidth: '650px'
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <SnackAlert
                type={type}
                open={showAlert}
                message={message}
                handleClose={() => setShowAlert(false)}
            />
        </>
    )
}

export default Profile