import axios from "axios"
import { host } from "./Host"
import { store } from "../Redux/store";
import { logoutUser } from "../Redux/Slices/authSlice";


export const Get = async (path) => {
    let Token = store.getState().auth.userData?.token;
    if (!Token) {
        Token = JSON.parse(localStorage.getItem('User'))?.token;
    }
    try {
        const response = await axios.get(`${host}${path}`, { headers: { "Authorization": 'Bearer ' + Token } });
        return response.data;
    } catch (err) {
        if (err?.response?.data === 'User is not authenticated!') {
            store.dispatch(logoutUser());
        }
        throw err.response;
    }
}

export const Post = async (path, data) => {
    let Token = store.getState().auth.userData?.token;
    if (!Token) {
        Token = JSON.parse(localStorage.getItem('User'))?.token;
    }
    try {
        const response = await axios.post(`${host}${path}`, data, { headers: { "Authorization": 'Bearer ' + Token } });
        return response.data;
    } catch (err) {
        if (err?.response?.data === 'User is not authenticated!') {
            store.dispatch(logoutUser());
        }
        throw err.response;
    }
}

export const Put = async (path, data) => {
    let Token = store.getState().auth.userData?.token;
    if (!Token) {
        Token = JSON.parse(localStorage.getItem('User'))?.token;
    }
    try {
        const response = await axios.put(`${host}${path}`, data, { headers: { "Authorization": 'Bearer ' + Token } });
        return response.data;
    } catch (err) {
        if (err?.response?.data === 'User is not authenticated!') {
            store.dispatch(logoutUser());
        }
        throw err.response;
    }
}

export const GetWithoutAuth = async (url) => {
    try {
        const response = await axios.get(url);
        return response?.data;
    } catch (err) {
        throw err.response;
    }
}