import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#FFF',
            darkBlue: '#1782CA',
            lightBlue: '#59BEF4',
        },
    },
});

export default theme;