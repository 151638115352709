import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userStatus: '',
    userData: null,
    categoriesList: []
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginUser: (state, action) => {
            state.userStatus = "Login";
            state.userData = action.payload;
        },
        logoutUser: (state) => {
            state.userStatus = '';
            state.userData = null;
            localStorage.removeItem('User');
            window.location.pathname = '/login';
        },
        setCategoriesList: (state, action) => {
            state.categoriesList = action.payload;
        }
    },
})

export const { loginUser, logoutUser, setCategoriesList } = authSlice.actions

export default authSlice