import React from 'react'

const CheckedIcon = ({ checked = true }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
            <path d="M0 8.65093C0 4.41802 3.43145 0.986572 7.66436 0.986572C11.8973 0.986572 15.3287 4.41802 15.3287 8.65093C15.3287 12.8838 11.8973 16.3153 7.66436 16.3153C3.43145 16.3153 0 12.8838 0 8.65093Z" fill={checked ? "#59BEF4" : '#D9D9D9'} />
            {checked &&
                <g filter="url(#filter0_d_9_24709)">
                    <path d="M10.5562 6.09613L6.74342 10.4536L5.10938 8.81953" stroke="white" strokeWidth="1.43" strokeLinecap="round" strokeLinejoin="round" />
                </g>
            }
            <defs>
                <filter id="filter0_d_9_24709" x="0.394531" y="5.38113" width="14.8765" height="13.7874" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9_24709" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9_24709" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export default CheckedIcon