import React from 'react'

const PhoneIcon = ({ width = '21' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height="21" viewBox="0 0 21 21" fill="none">
            <path d="M19.8333 13.8889C18.375 13.8889 16.975 13.6667 15.6683 13.2556C15.26 13.1333 14.805 13.2222 14.4783 13.5222L11.9117 15.9667C8.61 14.3667 5.90333 11.8 4.22333 8.65556L6.79 6.2C7.11667 5.9 7.21 5.46667 7.08167 5.07778C6.65 3.83333 6.41667 2.5 6.41667 1.11111C6.41667 0.5 5.89167 0 5.25 0H1.16667C0.525 0 0 0.5 0 1.11111C0 11.5444 8.87833 20 19.8333 20C20.475 20 21 19.5 21 18.8889V15C21 14.3889 20.475 13.8889 19.8333 13.8889ZM10.5 0V11.1111L14 7.77778H21V0H10.5Z" fill="white" />
        </svg>
    )
}

export default PhoneIcon