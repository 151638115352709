
const Apartment = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 35 32" fill="none">
            <path d="M33.9 31.2173H31.4385V14.0206H32.3346C32.5502 14.0206 32.726 13.8447 32.726 13.6292V11.3265C32.726 11.111 32.5502 10.9351 32.3346 10.9351H23.5718V5.88727H24.2751C24.4906 5.88727 24.6664 5.71145 24.6664 5.49592V3.48812C24.6664 3.2726 24.4906 3.09677 24.2751 3.09677H21.2294V0.782701H22.0574C22.273 0.782701 22.4488 0.606877 22.4488 0.391351C22.4488 0.175824 22.273 0 22.0574 0H12.234C12.0184 0 11.8426 0.175824 11.8426 0.391351C11.8426 0.606877 12.0184 0.782701 12.234 0.782701H13.062V3.09677H10.022C9.80645 3.09677 9.63063 3.2726 9.63063 3.48812V5.49592C9.63063 5.71145 9.80645 5.88727 10.022 5.88727H10.7253V10.9351H1.95675C1.74123 10.9351 1.5654 11.111 1.5654 11.3265V13.6292C1.5654 13.8447 1.74123 14.0206 1.95675 14.0206H2.85289V31.2173H0.391351C0.175824 31.2173 0 31.3931 0 31.6087C0 31.8242 0.175824 32 0.391351 32H33.9C34.1156 32 34.2914 31.8242 34.2914 31.6087C34.2914 31.3931 34.1156 31.2173 33.9 31.2173ZM31.9433 11.7178V13.2322H31.0471H23.5718V11.7178H31.9433ZM13.8504 0.782701H20.441V3.09677H13.8504V0.782701ZM10.419 3.87948H23.8837V5.0989H10.419V3.87948ZM2.3481 11.7178H10.7253V13.2322H3.24424H2.3481V11.7178ZM3.64126 14.0206H10.7253V31.2173H3.64126V14.0206ZM13.9865 31.2173V20.1631H20.3105V31.2173H13.9865ZM21.0989 31.2173V20.1631H21.5867C21.8022 20.1631 21.978 19.9872 21.978 19.7717C21.978 19.5562 21.8022 19.3804 21.5867 19.3804H12.7104C12.4949 19.3804 12.319 19.5562 12.319 19.7717C12.319 19.9872 12.4949 20.1631 12.7104 20.1631H13.1982V31.2173H11.508V5.88727H22.7834V31.2173H21.0989ZM23.5718 31.2173V14.0206H30.6501V31.2173H23.5718Z" fill="#A8A8A8" />
            <path d="M4.43457 14.9055H6.51043V17.1345H4.43457V14.9055Z" fill="#A8A8A8" />
            <path d="M7.65063 14.9055H9.72649V17.1345H7.65063V14.9055Z" fill="#A8A8A8" />
            <path d="M4.43457 18.1779H6.51043V20.4069H4.43457V18.1779Z" fill="#A8A8A8" />
            <path d="M7.65063 18.1779H9.72649V20.4069H7.65063V18.1779Z" fill="#A8A8A8" />
            <path d="M4.43457 21.4449H6.51043V23.6739H4.43457V21.4449Z" fill="#A8A8A8" />
            <path d="M7.65063 21.4449H9.72649V23.6739H7.65063V21.4449Z" fill="#A8A8A8" />
            <path d="M4.43457 24.7117H6.51043V26.9407H4.43457V24.7117Z" fill="#A8A8A8" />
            <path d="M7.65063 24.7117H9.72649V26.9407H7.65063V24.7117Z" fill="#A8A8A8" />
            <path d="M4.43457 27.9844H6.51043V30.2134H4.43457V27.9844Z" fill="#A8A8A8" />
            <path d="M7.65063 27.9844H9.72649V30.2134H7.65063V27.9844Z" fill="#A8A8A8" />
            <path d="M24.6846 14.9055H26.7604V17.1345H24.6846V14.9055Z" fill="#A8A8A8" />
            <path d="M27.8933 14.9055H29.9692V17.1345H27.8933V14.9055Z" fill="#A8A8A8" />
            <path d="M24.6846 18.1779H26.7604V20.4069H24.6846V18.1779Z" fill="#A8A8A8" />
            <path d="M27.8933 18.1779H29.9692V20.4069H27.8933V18.1779Z" fill="#A8A8A8" />
            <path d="M24.6846 21.4449H26.7604V23.6739H24.6846V21.4449Z" fill="#A8A8A8" />
            <path d="M27.8933 21.4449H29.9692V23.6739H27.8933V21.4449Z" fill="#A8A8A8" />
            <path d="M24.6846 24.7117H26.7604V26.9407H24.6846V24.7117Z" fill="#A8A8A8" />
            <path d="M27.8933 24.7117H29.9692V26.9407H27.8933V24.7117Z" fill="#A8A8A8" />
            <path d="M24.6846 27.9844H26.7604V30.2134H24.6846V27.9844Z" fill="#A8A8A8" />
            <path d="M27.8933 27.9844H29.9692V30.2134H27.8933V27.9844Z" fill="#A8A8A8" />
            <path d="M12.5186 6.74927H14.3052V9.68156H12.5186V6.74927Z" fill="#A8A8A8" />
            <path d="M15.0935 6.74927H16.8801V9.68156H15.0935V6.74927Z" fill="#A8A8A8" />
            <path d="M17.6619 6.74927H19.4485V9.68156H17.6619V6.74927Z" fill="#A8A8A8" />
            <path d="M20.2361 6.74927H22.0227V9.68156H20.2361V6.74927Z" fill="#A8A8A8" />
            <path d="M12.5186 10.6117H14.3052V13.544H12.5186V10.6117Z" fill="#A8A8A8" />
            <path d="M15.0935 10.6117H16.8801V13.544H15.0935V10.6117Z" fill="#A8A8A8" />
            <path d="M17.6619 10.6117H19.4485V13.544H17.6619V10.6117Z" fill="#A8A8A8" />
            <path d="M20.2361 10.6117H22.0227V13.544H20.2361V10.6117Z" fill="#A8A8A8" />
            <path d="M12.5186 14.4686H14.3052V17.4009H12.5186V14.4686Z" fill="#A8A8A8" />
            <path d="M15.0935 14.4686H16.8801V17.4009H15.0935V14.4686Z" fill="#A8A8A8" />
            <path d="M17.6619 14.4686H19.4485V17.4009H17.6619V14.4686Z" fill="#A8A8A8" />
            <path d="M20.2361 14.4686H22.0227V17.4009H20.2361V14.4686Z" fill="#A8A8A8" />
            <path d="M18.9653 25.0409C18.7498 25.0409 18.574 25.2167 18.574 25.4322V25.8123C18.574 26.0278 18.7498 26.2036 18.9653 26.2036C19.1809 26.2036 19.3567 26.0278 19.3567 25.8123V25.4322C19.3567 25.2167 19.1809 25.0409 18.9653 25.0409Z" fill="#A8A8A8" />
        </svg>
    )
}

export default Apartment