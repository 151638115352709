import React, { useEffect, useReducer, useState } from 'react'
import InputField from '../Components/InputField'
import { Box, Grid, IconButton, InputAdornment, Typography, useMediaQuery, useTheme } from '@mui/material';
import FormUser from '../Assets/svg/FormUser';
import { formReducer, validateEmail } from '../utils/globalFunctions';
import Lock from '../Assets/svg/Lock';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CustomCheckBox from '../Components/CustomCheckBox';
import { Link, useNavigate } from 'react-router-dom';
import CustomBtn from '../Components/CustomBtn';
import { signIn } from '../Endpoints/AuthEndpoints';
import SnackAlert from '../Components/SnackAlert';
import { useDispatch } from 'react-redux';
import { loginUser } from '../Redux/Slices/authSlice';
import { getGeolocation, saveVisitsOnWeek } from '../Endpoints/UserEndpoints';
import ReCAPTCHA from 'react-google-recaptcha';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { palette: { primary } } = useTheme();
    const isLarge = useMediaQuery('(max-width:1059px)');
    const isMedium = useMediaQuery('(max-width:680px)');
    const isSmall = useMediaQuery('(max-width:430px)');

    const [type, setType] = useState();
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [rememberMe, setRememberMe] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const userData = JSON.parse(localStorage.getItem('User'));
    const [formData, setFormData] = useReducer(formReducer, {});
    const [recaptcha, setRecaptcha] = useState(process.env.NODE_ENV === "development");



    const handleLogin = async () => {
        setIsSubmitted(true);
        if (validateEmail(formData['email']) && formData['password'] && recaptcha) {
            setLoading(true);
            try {
                const response = await signIn(formData);
                if (response?.success) {
                    let userData = response.data.customerCredentials.customerDetails;
                    userData.token = response.data.customerCredentials.token;
                    dispatch(loginUser(userData));
                    localStorage.setItem('User', JSON.stringify(userData));
                    const checkoutDetails = JSON.parse(localStorage.getItem('bookingDetails'));
                    if (checkoutDetails) {
                        navigate(`/service/${checkoutDetails?.bookingDetails?._id}`)
                    } else {
                        navigate('/');
                    }
                    setType('success');
                    setMessage('Successfully Logged In');
                    setShowAlert(true);
                    setLoading(false);
                } else {
                    setType('error');
                    setMessage(response?.message ?? 'Invalid credentails!')
                    setShowAlert(true);
                    setLoading(false);
                }
            } catch (e) {
                setType('error');
                setMessage('Error occured please try again!')
                setShowAlert(true);
                setLoading(false);
                console.log("ERR", e)
            }
        }
    }
    useEffect(() => {
        (async () => {
            try {
                const response = await getGeolocation();
                saveVisitsOnWeek({ IPAddress: response?.IPv4 })
            } catch (e) {
                console.error(e)
            }
        })();
    }, [])

    if (userData) {
        navigate('/');
        return;
    }

    return (
        <>
            <Box
                sx={{
                    m: isSmall ? '15px' : isMedium ? '30px 25px' : isLarge ? '50px' : { xl: '100px 170px', xs: '100px' }
                }}
            >
                <Grid container spacing={{ lg: 5, sm: 3, xs: 1 }} alignItems={'center'}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: { md: 'none', xs: 'block' }
                        }}
                    >
                        <Box
                            component={'div'}
                            sx={{
                                background: '#E2FCFF',
                                px: '30px',
                                pt: '20px',
                                textAlign: 'center',
                                '& img': {
                                    width: '-webkit-fill-available',
                                    height: 'auto',
                                    maxWidth: '400px'
                                }
                            }}
                        >
                            <Box
                                component={'span'}
                                sx={{
                                    display: 'block',
                                    mb: '20px',
                                }}
                            >
                                <Typography color={'#1C1C1C'} fontWeight={700} fontSize={{ sm: 30, xs: 20 }} textAlign={'left'}>
                                    Welcome Back !
                                </Typography>
                                <Typography
                                    color={'#1C1C1C'}
                                    fontWeight={500}
                                    fontSize={{ sm: 16, xs: 14 }}
                                    textAlign={'left'}
                                    sx={{
                                        '& a': {
                                            fontSize: { sm: 16, xs: 14 }
                                        }
                                    }}
                                >
                                    Didn't have an account?&nbsp;
                                    <Link
                                        style={{
                                            color: primary.darkBlue,
                                            fontWeight: 700,

                                        }}
                                        to={'/register'}
                                    >
                                        Sign up
                                    </Link>
                                </Typography>
                            </Box>
                            <img
                                src='/images/login.png'
                                alt='login'
                            />
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box
                            component={'span'}
                            sx={{
                                display: { md: 'block', xs: 'none' }
                            }}
                        >
                            <Typography color={'#1C1C1C'} fontWeight={700} fontSize={40}>
                                Welcome Back !
                            </Typography>
                            <Typography color={'#1C1C1C'} fontWeight={500} fontSize={20} component={'span'}>
                                Didn't have an account?&nbsp;
                                <Link
                                    style={{
                                        color: primary.darkBlue,
                                        fontWeight: 700,
                                        fontSize: 20
                                    }}
                                    to={'/register'}
                                >
                                    Sign up
                                </Link>
                            </Typography>
                        </Box>
                        <Box
                            mt={'30px'}
                            component={'div'}
                        >
                            <Box component={'div'}>
                                <InputField
                                    type='email'
                                    name='email'
                                    required={true}
                                    label='Email Address'
                                    onChange={setFormData}
                                    isSubmitted={isSubmitted}
                                    value={formData['email']}
                                    placeholder='Email Address'
                                    inputStyle={{
                                        fontSize: { md: 16, xs: 14 },
                                        '& input': {
                                            pb: 0,
                                        },
                                        'svg': {
                                            minWidth: 20
                                        }
                                    }}
                                    labelStyle={{
                                        fontSize: { md: 16, xs: 14 },
                                    }}
                                    startAdornment={
                                        <InputAdornment position="start" >
                                            <FormUser />
                                        </InputAdornment>
                                    }

                                />
                            </Box>
                            <Box
                                component={'div'}
                                mt='30px'
                            >
                                <InputField
                                    type={showPassword ? 'text' : 'password'}
                                    name='password'
                                    label='Password'
                                    required={true}
                                    onChange={setFormData}
                                    isSubmitted={isSubmitted}
                                    value={formData['password']}
                                    placeholder='Password'
                                    labelStyle={{
                                        fontSize: { md: 16, xs: 14 },
                                    }}
                                    inputStyle={{
                                        fontSize: { md: 16, xs: 14 },
                                        '& input': {
                                            pb: 0,
                                        },
                                        'svg': {
                                            minWidth: 20
                                        }
                                    }}
                                    startAdornment={
                                        <InputAdornment position="start" >
                                            <Lock />
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end" >
                                            <IconButton
                                                sx={{
                                                    p: 0,
                                                    m: 0,
                                                }}
                                                onClick={() => setShowPassword((prev) => !prev)}
                                            >
                                                {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Box>
                            <Box
                                sx={{
                                    mt: '10px',
                                    gap: '30px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <CustomCheckBox
                                    label="Remember Me"
                                    checked={rememberMe}
                                    onChange={() => setRememberMe((prev) => !prev)}
                                    checkBoxStyle={{
                                        '.MuiFormControlLabel-label': {
                                            fontSize: { sm: 14, xs: 12 },
                                            p: { sm: '5px', xs: '2px' },
                                            color: '#1C1C1C',
                                            fontWeight: 500
                                        },
                                        'svg': {
                                            width: { sm: '20px', xs: '18px' },
                                            height: { sm: '20px', xs: '18px' }
                                        }
                                    }}
                                />
                                <Link
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                >
                                    <Typography color={'#1C1C1C'} fontSize={{ sm: 14, xs: 12 }} fontWeight={700}>
                                        Forgot Password?
                                    </Typography>
                                </Link>
                            </Box>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RE_CAPTCHA_SITE_KEY}
                                onChange={() => setRecaptcha(true)}
                                onExpired={() => setRecaptcha(false)}
                            />
                            <CustomBtn
                                title='Login'
                                loading={loading}
                                onClick={handleLogin}
                                btnStyle={{
                                    textTransform: 'capitalize',
                                    mt: '20px',
                                    width: '100%',
                                    fontSize: { sm: 16, xs: 14 }
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        sx={{
                            display: { md: 'block', xs: 'none' }
                        }}
                    >
                        <Box
                            component={'div'}
                            sx={{
                                background: '#E2FCFF',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <img
                                src='/images/login.png'
                                alt='login'
                                style={{
                                    width: '-webkit-fill-available',
                                    height: 'auto',
                                    maxWidth: '650px'
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <SnackAlert
                type={type}
                open={showAlert}
                message={message}
                handleClose={() => setShowAlert(false)}
            />
        </>
    )
}

export default Login