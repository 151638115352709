import { Button, CircularProgress, useTheme } from '@mui/material'
import React from 'react'

const CustomBtn = ({ variant = 'contained', onClick = () => { }, title = '', btnStyle, endIcon, loading = false, disabled = false }) => {
    const { palette: { primary } } = useTheme();
    return (
        <Button
            variant={variant}
            onClick={onClick}
            sx={[{
                color: variant === 'outlined' ? primary.lightBlue : primary.main,
                fontSize: '16px',
                fontWeight: 800,
                fontFamily: "'Nunito Sans', sans-serif",
                background: variant === 'outlined' ? 'transparent' : primary.darkBlue,
                border: '2px solid',
                borderColor: variant === 'outlined' ? primary.lightBlue : 'transparent',
                borderRadius: 0,
                whiteSpace: 'nowrap',
                p: '7px 35px',
                '&:hover': {
                    background: variant === 'outlined' ? primary.darkBlue : primary.lightBlue,
                    border: `2px solid ${variant === 'outlined' ? primary.darkBlue : primary.lightBlue}`,
                    color: primary.main,
                },
            }, btnStyle]}
            endIcon={endIcon}
            disabled={disabled || loading}
            startIcon={loading ? <CircularProgress size={20} /> : undefined}
        >
            {title}
        </Button>
    )
}

export default CustomBtn