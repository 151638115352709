import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'

const TimeSlot = ({ isSelected, handleSelect, item }) => {
    const { palette: { primary } } = useTheme();
    return (
        <Box
            sx={{
                background: isSelected ? primary.darkBlue : '#E4EEF5',
                width: 'fit-content',
                p: { lg: '10px 20px',sm: '10px 16px', xs: '8px 15px' },
                cursor: item.disabled ? 'not-allowed' : 'pointer',
            }}
            onClick={() => item.disabled ? null : handleSelect(item, (isSelected ? 'remove' : 'add'))}
        >
            <Typography color={item.disabled ? 'rgba(0, 0, 0, 0.38)' : isSelected ? primary.main : '#262D33'} fontSize={{ sm: 14, xs: 12 }}>
                {item.start} - {item.end}
            </Typography>
        </Box>
    )
}

export default TimeSlot