import { Alert, Snackbar } from '@mui/material'
import React from 'react'

const SnackAlert = ({ open = false, message = '', handleClose = () => { }, type = "info" }) => {
    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }} variant='filled'>
                {message}
            </Alert>
        </Snackbar>
    )
}

export default SnackAlert