import { useTheme } from '@emotion/react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'

const Dropdown = ({ onChange = () => { }, fullWidth = true, value = '', options = [], label = '', inputStyle, containerStyle }) => {
    const { palette: { primary } } = useTheme();
    return (
        <FormControl
            fullWidth={fullWidth}
            sx={[{
                '.MuiInputBase-root': {
                    height: '100%',
                },
            }, containerStyle]}
        >
            <InputLabel>{label}</InputLabel>
            <Select
                value={value}
                label={label}
                onChange={onChange}
                MenuProps={{
                    sx: {
                        '& .MuiList-root': {
                            py: { sm: '8px', xs: 0 }
                        },
                        '& li': {
                            fontSize: { sm: 16, xs: 14 },
                            p: '10px',
                            minHeight: 'auto'
                        }
                    }
                }}
                sx={[{
                    '.MuiSelect-select': {
                        fontWeight: 600,
                        fontFamily: "Nunito Sans"
                    },
                    '.MuiSvgIcon-root': {
                        top: 'calc(50% - 1em)',
                        width: '2em',
                        height: '2em',
                        color: primary.main,
                    },
                }, inputStyle]}
            >
                {options.map(x => <MenuItem key={x._id} value={`${x._id}+${x?.Category}`}>{x?.Name ?? x?.Category}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

export default Dropdown