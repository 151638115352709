import React, { useReducer, useRef, useState } from 'react'
import InputField from '../Components/InputField'
import { Avatar, Box, Checkbox, Grid, IconButton, InputAdornment, Typography, useMediaQuery, useTheme } from '@mui/material';
import FormUser from '../Assets/svg/FormUser';
import { formReducer, validateEmail, validatePassword } from '../utils/globalFunctions';
import Lock from '../Assets/svg/Lock';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Link, useNavigate } from 'react-router-dom';
import CustomBtn from '../Components/CustomBtn';
import Placeholder from '../Assets/svg/Placeholder';
import { requestRegistrationOTP } from '../Endpoints/AuthEndpoints';
import SnackAlert from '../Components/SnackAlert';
import OTPModal from '../Components/Modal/OTPModal';
import CheckedIcon from '../Assets/svg/CheckedIcon';

const Register = () => {
    const imageRef = useRef();
    const navigate = useNavigate();
    const { palette: { primary } } = useTheme();
    const isLarge = useMediaQuery('(max-width:1059px)');
    const isMedium = useMediaQuery('(max-width:680px)');
    const isSmall = useMediaQuery('(max-width:430px)');

    const [type, setType] = useState();
    const [image, setImage] = useState(null);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const userData = JSON.parse(localStorage.getItem('User'));
    const [formData, setFormData] = useReducer(formReducer, {});

    if (userData) {
        navigate('/');
        return;
    }

    const handleImage = (e) => {
        const image = e.target.files[0];
        setFormData({
            target: {
                name: "profilePic",
                value: image
            }
        })
        setImage(URL.createObjectURL(image))
    }

    const removeImage = () => {
        setFormData({
            target: {
                name: "profilePic",
                value: null
            }
        })
        setImage(null)
    }

    const handleRegister = async () => {
        setIsSubmitted(true);
        if (formData['termCondition'] && validateEmail(formData['email']) && validatePassword(formData['password'])) {
            if (formData['password'] === formData['cPassword']) {
                try {
                    const response = await requestRegistrationOTP({ email: formData['email'], phone: formData['phone'], type: 'New' });
                    if (response?.success) {
                        setLoading(false);
                        setType('success');
                        setMessage("OTP sent succesfully");
                        setShowAlert(true);
                        setShowModal(true);
                        setIsSubmitted(false);
                    } else {
                        setType('error');
                        setMessage(response?.data ?? 'Error occured please try again!')
                        setShowAlert(true);
                        setLoading(false);
                    }
                } catch (e) {
                    console.log("ERR", e)
                    setType('error');
                    setMessage('Error occured please try again!')
                    setShowAlert(true);
                    setLoading(false);
                }
            } else {
                setType('error');
                setMessage("Password and Confirm Password didn't match!");
                setShowAlert(true);
            }
        }
    }

    return (
        <>
            <Box
                sx={{
                    m: isSmall ? '15px' : isMedium ? '30px 25px' : isLarge ? '50px' : { xl: '100px 170px', xs: '100px' }
                }}
            >
                <Grid container spacing={{ lg: 5, sm: 3, xs: 1 }} alignItems={'center'}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: { md: 'none', xs: 'block' }
                        }}
                    >
                        <Box
                            component={'div'}
                            sx={{
                                textAlign: 'center',
                                '& img': {
                                    width: '-webkit-fill-available',
                                    height: 'auto',
                                    maxWidth: '400px'
                                }
                            }}
                        >
                            <img
                                src='/images/signup.png'
                                alt='login'
                            />
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box
                            component={'span'}
                        >
                            <Typography color={'#1C1C1C'} fontWeight={700} fontSize={{ md: 40, sm: 30, xs: 20 }}>
                                Sign up to Play-It
                            </Typography>
                            <Typography
                                color={'#1C1C1C'}
                                fontWeight={500}
                                fontSize={{ md: 20, sm: 16, xs: 14 }}
                                component={'span'}
                                sx={{
                                    '& a': {
                                        fontSize: { md: 20, sm: 16, xs: 14 }
                                    }
                                }}
                            >
                                Already have an account?&nbsp;
                                <Link
                                    style={{
                                        color: primary.darkBlue,
                                        fontWeight: 700,
                                    }}
                                    to={'/login'}
                                >
                                    Login
                                </Link>
                            </Typography>
                        </Box>
                        <Box
                            component={'div'}
                            sx={{
                                mt: '20px',
                                display: 'flex',
                                gap: { sm: '15px', xs: '5px' }
                            }}
                        >
                            {image ?
                                <Avatar
                                    src={image}
                                    sx={{
                                        bgcolor: primary.main,
                                        width: { sm: '80px', xs: '60px' },
                                        height: { sm: '80px', xs: '60px' }
                                    }}
                                    alt="Remy Sharp"
                                />
                                :
                                <Avatar
                                    sx={{
                                        bgcolor: primary.main,
                                        width: { sm: '80px', xs: '60px' },
                                        height: { sm: '80px', xs: '60px' }
                                    }}
                                    alt="Remy Sharp"
                                >
                                    <Placeholder />
                                </Avatar>
                            }
                            <Box
                                component={'div'}
                                sx={{
                                    mt: { sm: '10px', xs: '5px' }
                                }}
                            >
                                <Typography color={'#1C1C1C'} fontSize={{ sm: 18, xs: 14 }} fontWeight={{ sm: 400, xs: 600 }} component={'span'}>
                                    Profile picture&nbsp;
                                    <Typography color={'#1C1C1C'} fontSize={{ sm: 14, xs: 12 }} fontWeight={{ sm: 400, xs: 600 }} component={'span'}>
                                        (Optional)
                                    </Typography>
                                </Typography>
                                <Box
                                    component={'div'}
                                    sx={{
                                        mt: '2px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: { sm: '20px', xs: '10px' }
                                    }}
                                >
                                    <CustomBtn
                                        title='Upload Image'
                                        onClick={() => imageRef.current.click()}
                                        btnStyle={{
                                            color: '#23252D',
                                            fontWeight: 600,
                                            textTransform: 'capitalize',
                                            border: '1px solid #59BEF4',
                                            background: '#E0F4FF',
                                            p: { sm: '3px 19px', xs: '0px 10px' },
                                            boxShadow: 'none',
                                            fontSize: { sm: 14, xs: 12 },
                                            '&:hover': {
                                                borderWidth: 1
                                            }
                                        }}
                                    />
                                    <input
                                        type='file'
                                        ref={imageRef}
                                        accept="image/*"
                                        onChange={handleImage}
                                        style={{ display: 'none' }}
                                    />
                                    <CustomBtn
                                        onClick={removeImage}
                                        title='Remove Image'
                                        btnStyle={{
                                            color: '#CA0F2C',
                                            fontWeight: 600,
                                            textTransform: 'capitalize',
                                            border: '1px solid #FFD6DC',
                                            background: '#FFD6DC',
                                            p: { sm: '3px 19px', xs: '0px 10px' },
                                            boxShadow: 'none',
                                            fontSize: { sm: 14, xs: 12 },
                                            '&:hover': {
                                                background: '#CA0F2C',
                                                color: '#FFD6DC',
                                                borderColor: '#CA0F2C',
                                                borderWidth: 1
                                            }
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            mt={'30px'}
                            component={'div'}
                        >
                            <Grid container spacing={{ lg: 3, sm: 1, xs: 2 }}>
                                <Grid item sm={6} xs={12}>
                                    <InputField
                                        name='firstName'
                                        label='First Name'
                                        value={formData['firstName']}
                                        isSubmitted={isSubmitted}
                                        placeholder='First Name'
                                        required={true}
                                        onChange={setFormData}
                                        inputStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                            '& input': {
                                                pb: 0,
                                            },
                                            'svg': {
                                                minWidth: 20
                                            }
                                        }}
                                        labelStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" >
                                                <FormUser />
                                            </InputAdornment>
                                        }

                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <InputField
                                        name='lastName'
                                        label='Last Name'
                                        isSubmitted={isSubmitted}
                                        value={formData['lastName']}
                                        placeholder='Last Name'
                                        required={true}
                                        onChange={setFormData}
                                        inputStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                            '& input': {
                                                pb: 0,
                                            },
                                            'svg': {
                                                minWidth: 20
                                            }
                                        }}
                                        labelStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" >
                                                <FormUser />
                                            </InputAdornment>
                                        }

                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <InputField
                                        type='email'
                                        name='email'
                                        label='Email Address'
                                        isSubmitted={isSubmitted}
                                        value={formData['email']}
                                        placeholder='Email Address'
                                        required={true}
                                        onChange={setFormData}
                                        inputStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                            '& input': {
                                                pb: 0,
                                            },
                                        }}
                                        labelStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <InputField
                                        type='phone'
                                        name='phone'
                                        label='Phone Number'
                                        value={formData['phone']}
                                        isSubmitted={isSubmitted}
                                        placeholder='Phone Number'
                                        required={true}
                                        onChange={setFormData}
                                        inputStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                            '& input': {
                                                pb: 0,
                                            },
                                        }}
                                        labelStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <InputField
                                        type={showPassword ? 'text' : 'password'}
                                        name='password'
                                        label='Password'
                                        isSubmitted={isSubmitted}
                                        value={formData['password']}
                                        placeholder='Password'
                                        required={true}
                                        onChange={setFormData}
                                        labelStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                        }}
                                        inputStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                            '& input': {
                                                pb: 0,
                                            },
                                            'svg': {
                                                minWidth: 20
                                            }
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" >
                                                <Lock />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end" >
                                                <IconButton
                                                    sx={{
                                                        p: 0,
                                                        m: 0,
                                                    }}
                                                    onClick={() => setShowPassword((prev) => !prev)}
                                                >
                                                    {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <InputField
                                        type={showCPassword ? 'text' : 'password'}
                                        name='cPassword'
                                        label='Confirm Password'
                                        required={true}
                                        onChange={setFormData}
                                        isSubmitted={isSubmitted}
                                        value={formData['cPassword']}
                                        placeholder='Confirm Password'
                                        labelStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                        }}
                                        inputStyle={{
                                            fontSize: { md: 16, xs: 14 },
                                            '& input': {
                                                pb: 0,
                                            },
                                            'svg': {
                                                minWidth: 20
                                            }
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start" >
                                                <Lock />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end" >
                                                <IconButton
                                                    sx={{
                                                        p: 0,
                                                        m: 0,
                                                    }}
                                                    onClick={() => setShowCPassword((prev) => !prev)}
                                                >
                                                    {showCPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Box
                                sx={{
                                    mt: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Checkbox
                                 checkedIcon={<CheckedIcon />}
                                 icon={<CheckedIcon  checked={false}/>}
                                    sx={{
                                        '&.Mui-checked': {
                                            color: `${primary.lightBlue} !important`
                                        },
                                        'svg': {
                                            fontSize:25
                                        }
                                    }}
                                    onChange={(e) => {
                                        setFormData({
                                            target: {
                                                name: 'termCondition',
                                                value: e.target.checked
                                            }
                                        })
                                    }}
                                    name={'termCondition'}
                                    checked={formData['termCondition']}
                                />
                                <Typography fontSize={{ sm: 14, xs: 12 }} p={{ sm: '5px', xs: '2px' }} color={'#1C1C1C'} fontWeight={500}>
                                    I agree to&nbsp;
                                    <Link
                                        style={{
                                            color: '#1C1C1C'
                                        }}
                                    >
                                        terms and conditions
                                    </Link>
                                </Typography>
                            </Box>
                            {(isSubmitted && !formData['termCondition']) && <Typography color={'rgb(211, 47, 47)'} fontSize={12}>Please agree to terms and conditions!</Typography>}
                            <CustomBtn
                                loading={loading}
                                onClick={handleRegister}
                                title='Create an Account Now!'
                                btnStyle={{
                                    textTransform: 'capitalize',
                                    mt: '20px',
                                    width: '100%',
                                    fontSize: { sm: 16, xs: 14 }
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        sx={{
                            display: { md: 'block', xs: 'none' }
                        }}
                    >
                        <img
                            src='/images/signup.png'
                            alt='login'
                            style={{
                                width: '-webkit-fill-available',
                                height: 'auto',
                                maxWidth: '650px'
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>

            <OTPModal
                open={showModal}
                formData={formData}
                handleClose={() => setShowModal(false)}
            />
            <SnackAlert
                type={type}
                open={showAlert}
                message={message}
                handleClose={() => setShowAlert(false)}
            />
        </>
    )
}

export default Register