import { InputLabel, Typography, useTheme } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { validateDate } from '../utils/globalFunctions'

const CustomDatePicker = ({ label = '', required = false, labelStyle, inputStyle, value, isSubmitted = false, onChange, name = '' }) => {
    const { palette: { primary } } = useTheme();

    const [error, setError] = useState(false);
    const [message, setMessage] = useState(false);

    useEffect(() => {
        if (isSubmitted && required) {
            if (!value) {
                setError(true);
                setMessage(`${label} is required!`);
            } else {
                if (validateDate(value)) {
                    setError(false);
                    setMessage('');
                } else {
                    setError(true);
                    setMessage(`Please enter a valid date of birth!`);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitted, required, value])

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
        >
            <InputLabel
                required={required}
                sx={[{
                    color: error ? 'rgb(211, 47, 47)' : '#222',
                    fontSize: '16px',
                    fontWeight: 600
                }, labelStyle]}
            >
                {label}
            </InputLabel>
            <DatePicker
                format='MM/DD/YYYY'
                maxDate={dayjs()}
                value={dayjs(value)}
                onChange={(e) => {
                    let item = {
                        target: {
                            name,
                            value: dayjs(e).format('MM/DD/YYYY') !== "Invalid Date" ? dayjs(e).format('MM/DD/YYYY') : ''
                        }
                    }
                    onChange(item);
                }}
                sx={[{
                    mt: '10px',
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                        background: '#F6F6F6',
                        fontFamily: "Nunito Sans",
                        borderRadius: 0,
                        border: error ? '1px solid rgb(211, 47, 47)' : 0,
                        minHeight: '52px'
                    },
                    '& fieldset': {
                        border: '0 !important'
                    },
                    '.Mui-focused': {
                        backgroundColor: '#F6FCFF',
                        border: `1px solid ${error ? 'rgb(211, 47, 47)' : primary.darkBlue}`,
                    },
                }, inputStyle]}
            />
            {message && <Typography color={'rgb(211, 47, 47)'} fontSize={12}>{message}</Typography>}
        </LocalizationProvider>
    )
}

export default CustomDatePicker