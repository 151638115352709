import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Box } from '@mui/material'

const Layout = ({ children }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                minHeight: '100vh',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}
            component={'div'}
        >
            <Header />
            {children}
            <Footer />
        </Box>
    )
}

export default Layout