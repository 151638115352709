
const MeetingIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="29" viewBox="0 0 36 29" fill="none">
            <path d="M11.5153 1.70581H8.9565C8.72077 1.70581 8.53003 2.0873 8.53003 2.55875C8.53003 3.0302 8.72077 3.41169 8.9565 3.41169H11.5153C11.751 3.41169 11.9418 3.0302 11.9418 2.55875C11.9418 2.0873 11.751 1.70581 11.5153 1.70581Z" fill="#A8A8A8" />
            <path d="M6.51413 1.70581H5.42857C5.25713 1.70581 5.11841 1.88188 5.11841 2.09948V6.42979C5.11841 6.64738 5.25713 6.82346 5.42857 6.82346C5.6 6.82346 5.73873 6.64738 5.73873 6.42979V2.49314H6.51413C6.68557 2.49314 6.82429 2.31707 6.82429 2.09948C6.82429 1.88188 6.68557 1.70581 6.51413 1.70581Z" fill="#A8A8A8" />
            <path d="M5.97135 20.4706C5.4999 20.4706 5.11841 20.6549 5.11841 20.8828V25.176C5.11841 25.4039 5.4999 25.5882 5.97135 25.5882C6.4428 25.5882 6.82429 25.4039 6.82429 25.176V20.8828C6.82429 20.6549 6.4428 20.4706 5.97135 20.4706Z" fill="#A8A8A8" />
            <path d="M35.0622 27.5505H33.1768V4.88146C33.1768 4.48087 32.8363 4.15671 32.4154 4.15671H19.7942V0.724752C19.7942 0.324157 19.4537 0 19.0329 0H3.27203C2.85122 0 2.51071 0.324157 2.51071 0.724752V27.5505H0.761317C0.340511 27.5505 0 27.8747 0 28.2752C0 28.6758 0.340511 29 0.761317 29H19.0329C19.4537 29 19.7942 28.6758 19.7942 28.2752V5.60621H31.6541V28.2752C31.6541 28.6758 31.9946 29 32.4154 29H35.0622C35.483 29 35.8235 28.6758 35.8235 28.2752C35.8235 27.8747 35.483 27.5505 35.0622 27.5505ZM4.03334 1.4495H18.2716V27.5505H4.03334V1.4495Z" fill="#A8A8A8" />
            <path d="M14.5008 11.9413C13.0894 11.9413 11.9417 13.089 11.9417 14.5004C11.9417 15.9112 13.0894 17.0589 14.5008 17.0589C15.9116 17.0589 17.0593 15.9112 17.0593 14.5004C17.0593 13.089 15.9116 11.9413 14.5008 11.9413ZM14.5008 15.6631C13.8595 15.6631 13.3374 15.1418 13.3374 14.5004C13.3374 13.8591 13.8595 13.3371 14.5008 13.3371C15.1421 13.3371 15.6635 13.8591 15.6635 14.5004C15.6635 15.1418 15.1421 15.6631 14.5008 15.6631Z" fill="#A8A8A8" />
            <path d="M34.1175 3.73907C34.1175 3.55808 34.0029 3.41162 33.8613 3.41162H32.6678C32.5262 3.41162 32.4116 3.55808 32.4116 3.73907C32.4116 3.92006 32.5262 4.06652 32.6678 4.06652H33.6051V8.20182C33.6051 8.38281 33.7197 8.52927 33.8613 8.52927C34.0029 8.52927 34.1175 8.38281 34.1175 8.20182V3.73907Z" fill="#A8A8A8" />
        </svg>
    )
}

export default MeetingIcon