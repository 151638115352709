import { Card, CardActionArea, CardContent, CardMedia, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { url } from '../utils/Host';
import { useNavigate } from 'react-router-dom';

const VenueCard = ({ venue }) => {
    const navigate = useNavigate();
    const isSmall = useMediaQuery('(max-width:430px)');
    return (
        <Card
            sx={{
                borderRadius: '5px',
                background: '#FDFDFD',
                boxShadow: '0px 2px 6px 1px rgba(0, 0, 0, 0.10)'
            }}
            onClick={() => navigate(`/venue/${venue?._id}`)}
        >
            <CardActionArea>
                <CardMedia
                    component="img"
                    height={isSmall ? 'auto' : "270"}
                    image={venue?.Images?.length > 0 ? `${url}/picAdmin/${venue?.Images[0]}` : undefined}
                    alt="venue"
                    sx={{
                        borderRadius: '5px',
                        maxHeight: isSmall ? '225px' : "270px"
                    }}
                />
                <CardContent>
                    <Typography color={'#15192C'} fontSize={isSmall ? 14 : 16} component="div" fontWeight={700} textAlign={'center'}>
                        {venue?.Name}
                    </Typography>
                    <Typography color="#707070" fontSize={isSmall ? 14 : 16} fontWeight={500} textAlign={'center'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'}>
                        {venue?.categories?.map(x => x?.Category)?.join(', ')}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default VenueCard