
const CreditCard = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 38 28" fill="none">
            <path d="M35.5757 0H2.42534C1.08828 0 0 1.08829 0 2.42479V4.22198V11.6107V25.0186C0 26.3557 1.08828 27.444 2.42534 27.444H35.5757C36.9128 27.444 38 26.3557 38 25.0186V11.6113V4.22198V2.42479C38 1.08774 36.9128 0 35.5757 0ZM36.9448 25.0192C36.9448 25.7747 36.3302 26.3893 35.5757 26.3893H2.42534C1.66982 26.3893 1.05522 25.7747 1.05522 25.0192V11.6113H36.9448V25.0192ZM36.9448 4.22198H1.05522V2.42479C1.05522 1.66982 1.66982 1.05522 2.42534 1.05522H35.5757C36.3302 1.05522 36.9448 1.66982 36.9448 2.42479V4.22198Z" fill="#A8A8A8" />
            <path d="M25.8625 24.2777C26.8689 24.2777 27.8125 23.8642 28.5019 23.1559C29.1913 23.8642 30.1349 24.2777 31.1408 24.2777C33.1775 24.2777 34.8354 22.6204 34.8354 20.5831C34.8354 18.5464 33.1781 16.8885 31.1408 16.8885C30.1349 16.8885 29.1918 17.302 28.5019 18.0103C27.8131 17.302 26.8695 16.8885 25.8625 16.8885C23.8257 16.8885 22.1678 18.5458 22.1678 20.5831C22.1678 22.6204 23.8252 24.2777 25.8625 24.2777ZM29.1349 18.8721C29.6341 18.2857 30.3625 17.9442 31.1397 17.9442C32.5954 17.9442 33.7786 19.1274 33.7786 20.5831C33.7786 22.0388 32.5954 23.222 31.1397 23.222C30.3625 23.222 29.6335 22.8805 29.1349 22.2941C29.4113 21.7673 29.5566 21.1831 29.5566 20.5831C29.5566 19.9826 29.4113 19.3978 29.1349 18.8721ZM25.8625 17.9448C26.7481 17.9448 27.5708 18.387 28.0613 19.129L28.0624 19.1301C28.3502 19.5609 28.5014 20.0639 28.5014 20.5836C28.5014 21.1012 28.3502 21.6036 28.0634 22.0356C28.0629 22.0367 28.0613 22.0378 28.0613 22.0378C27.5708 22.7797 26.7481 23.222 25.8625 23.222C24.4067 23.222 23.2236 22.0388 23.2236 20.5831C23.2236 19.1274 24.4067 17.9448 25.8625 17.9448Z" fill="#A8A8A8" />
        </svg>
    )
}

export default CreditCard