import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import CustomBtn from '../CustomBtn';
import { Close } from '@mui/icons-material';
import { requestRegistrationOTP, signIn, verifyOTP } from '../../Endpoints/AuthEndpoints';
import SnackAlert from '../SnackAlert';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../Redux/Slices/authSlice';
import { addNewCustomer } from '../../Endpoints/UserEndpoints';

const OTPModal = ({ open = false, handleClose = () => { }, formData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { palette: { primary } } = useTheme();

  const inputs = useRef([]);
  const [type, setType] = useState();
  const [code, setCode] = useState([]);
  const [message, setMessage] = useState('');
  const [progress, setProgress] = useState(60);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);

  const focusNextInput = (index) => {
    if (index < 3) {
      inputs.current[index + 1]?.focus();
    }
  };

  const focusPreviousInput = (index) => {
    if (index > 0) {
      inputs.current[index - 1]?.focus();
    }
  };

  const handleChange = (value, index) => {
    const formattedCode = value.replace(/[^0-9]/g, '');
    if (formattedCode.length <= 1) {
      setCode((prevCode) => {
        const newCode = [...prevCode];
        newCode[index] = formattedCode;
        return newCode;
      });
      if (!formattedCode) {
        focusPreviousInput(index)
      } else {
        inputs.current[index + 1]?.focus();
      }
    } else {
      formattedCode.split('').forEach((digit, i) => {
        if (index + i < 4) {
          setCode((prevCode) => {
            const newCode = [...prevCode];
            newCode[i] = digit;
            return newCode;
          });
          if (index + i === 3) {
            inputs.current[index + i].blur();
          }
        }
      });
    }
    if (index === 3 && formattedCode) {
      inputs.current[3].blur();
      return;
    }
    if (formattedCode.length === 1) {
      focusNextInput(index);
    }
  };

  const handleKeyPress = (e, index) => {
    if (e.key === 'Enter') {
      focusNextInput(index);
    }
  };

  const handleVerify = async () => {
    setLoading(true);
    try {
      const response = await verifyOTP({ email: formData['email'], otp: code.join(''), type: 'New' });
      if (response?.success) {
        setType('success');
        setMessage(response?.message)
        setShowAlert(true);
        registerCustomer();
      } else {
        setCode([]);
        setType('error');
        setMessage((response?.message === "jwt expired" || response?.message === "jwt must be provided")
          ? 'OTP is expired, Please try to resend otp!' : response?.message)
        setShowAlert(true);
        setLoading(false);
      }
    } catch (e) {
      setType('error');
      setMessage("Error occured please try again!");
      setShowAlert(true);
      setLoading(false);
      console.log("ERR", e)
    }
  }

  const registerCustomer = async () => {
    const data = new FormData();
    data.append('dob', formData.dob);
    data.append('email', formData.email);
    data.append('phone', formData.phone);
    data.append('gender', formData.gender);
    data.append('lastName', formData.lastName);
    data.append('password', formData.password);
    data.append('firstName', formData.firstName);
    data.append('profilePic', formData.profilePic);
    data.append('termCondition', formData.termCondition ? 'Yes' : 'No');
    try {
      const response = await addNewCustomer(data);
      if (response?.success) {
        loginCustomer();
      } else {
        setType('error');
        setMessage(response?.message);
        setShowAlert(true);
        setLoading(false);
      }
    } catch (e) {
      console.log("ERR", e)
      setType('error');
      setMessage("Error occured please try again!");
      setShowAlert(true);
      setLoading(false);
    }
  }

  const loginCustomer = async () => {
    try {
      const response = await signIn({ email: formData['email'], password: formData['password'] });
      if (response?.success) {
        let userData = response.data.customerCredentials.customerDetails;
        userData.token = response.data.customerCredentials.token;
        dispatch(loginUser(userData));
        localStorage.setItem('User', JSON.stringify(userData));
        navigate('/');
        setType('success');
        setMessage('Successfully Logged In');
        setShowAlert(true);
        setCode([]);
        setLoading(false);
      } else {
        setType('error');
        setMessage(response?.message ?? 'Error occured please try again!')
        setShowAlert(true);
        setLoading(false);
      }
    } catch (e) {
      console.log("ERR", e)
      setType('error');
      setMessage('Error occured please try again!')
      setShowAlert(true);
      setLoading(false);
    }
  }

  const handleResendOTP = async () => {
    setResendLoading(true);
    try {
      const response = await requestRegistrationOTP({ email: formData['email'], phone: formData['phone'], type: 'New' });
      if (response?.success) {
        setResendLoading(false);
        setType('success');
        setMessage("OTP sent succesfully");
        setShowAlert(true);
        setProgress(60);
      } else {
        setType('error');
        setMessage(response?.message ?? 'Error occured please try again!')
        setShowAlert(true);
        setResendLoading(false);
      }
    } catch (e) {
      setType('error');
      setMessage('Error occured please try again!')
      setShowAlert(true);
      setResendLoading(false);
      console.log("ERR", e)
    }
  }

  useEffect(() => {
    setCode([]);
    setProgress(60);
  }, [open])

  useEffect(() => {
    if (!resendLoading) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress === 1 ? clearInterval(timer) : prevProgress - 1));
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendLoading]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <Box
          component={'div'}
          sx={{
            gap: '20px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <DialogTitle>
            Enter Your Verification code
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              p: '13px 10px'
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <DialogContentText>
            We sent it on this {formData['phone']} number.
          </DialogContentText>
          <Box
            component={'div'}
            sx={{
              mt: '20px',
              gap: '20px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {[0, 1, 2, 3].map(i => (
              <TextField
                type='password'
                key={i}
                inputRef={(ref) => (inputs.current[i] = ref)}
                variant='standard'
                value={code[i] || ''}
                placeholder='0'
                onChange={(e) => handleChange(e.target.value, i)}
                onKeyDown={(e) => handleKeyPress(e, i)}
                sx={{
                  width: '70px',
                  '& input': {
                    textAlign: 'center',
                    fontSize: 18
                  },
                  '& .Mui-focused': {
                    backgroundColor: '#F6FCFF',
                    borderBottom: `1px solid  ${primary.darkBlue}`,
                  },
                }}
              />
            ))}
          </Box>
          <CustomBtn
            disabled={code.length <= 3}
            title='Verify and proceed'
            loading={loading}
            onClick={handleVerify}
            btnStyle={{
              textTransform: 'capitalize',
              mt: '20px',
              width: '100%',
              fontSize: { sm: 16, xs: 14 }
            }}
          />
          <Typography fontSize={{ sm: 18, xs: 16 }} color={'rgba(0, 0, 0, 0.42)'} mt={'20px'} textAlign={'center'}>
            Didn't receive the code?
          </Typography>
          <Box
            sx={{
              textAlign: 'center',
              width: '100%'
            }}
          >
            <CustomBtn
              onClick={handleResendOTP}
              disabled={progress >= 1}
              loading={resendLoading}
              title={progress ?? 'Resend code'}
              btnStyle={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                color: primary.darkBlue,
                fontSize: { sm: 16, xs: 14 },
                fontWeight: 700,
                textDecoration: progress < 1 ? 'underline' : 'none',
                textTransform: 'capitalize',
                p: '3px 10px'
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <SnackAlert
        type={type}
        open={showAlert}
        message={message}
        handleClose={() => setShowAlert(false)}
      />
    </>
  )
}

export default OTPModal;