import { Box, CircularProgress, useTheme } from '@mui/material'
import React from 'react'

const Loader = () => {
    const { palette: { primary } } = useTheme();

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                minHeight: '200px',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: primary.main
            }}
        >
            <CircularProgress
                sx={{
                    color: primary.darkBlue
                }}
            />
        </Box>
    )
}

export default Loader