import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { getFacilities } from '../Endpoints/UserEndpoints';
import { url } from '../utils/Host';
import Loader from '../Components/Loader';
import Pagination from '../Components/Pagination';
import VenueCard from '../Components/VenueCard';

const Venues = () => {
    const { palette: { primary } } = useTheme();
    const isSmall = useMediaQuery('(max-width:430px)');
    const isLarge = useMediaQuery('(max-width:1059px)');
    const isMedium = useMediaQuery('(max-width:680px)');

    const rowsPerpage = 6;
    const [page, setPage] = useState(0);
    const [venues, setVenues] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const handleChangePage = (pageNumber) => {
        setPage(pageNumber - 1);
    };

    const getVenues = async () => {
        setIsLoaded(false);
        try {
            const response = await getFacilities();
            setVenues(response?.data ?? []);
            setIsLoaded(true);
        } catch (e) {
            console.error("ERR", e)
        }
    }

    useEffect(() => {
        getVenues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {isLoaded ?
                <>
                    {/* Banner */}
                    <Box
                        sx={{
                            background: `linear-gradient(0deg, rgb(23 130 202 / 0%) 0%, rgb(23 130 202 / 0%) 100%), url('${url}/picAdmin/${venues[0]?.Images[0]}'), lightgray 50% / cover no-repeat`,
                            width: '100%',
                            height: { md: '600px', xs: isSmall ? '300px' : '400px' },
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'bottom'
                        }}
                    >
                        <Box
                            sx={{
                                py: isSmall ? '5px' : isLarge ? '10px' : '15px',
                                mt: 'auto',
                                width: '100%',
                                borderRadius: '0px 0px 5px 5px',
                                background: 'rgba(0, 0, 0, 0.25)',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: primary.main,
                                    fontFamily: 'Nunito Sans',
                                    fontSize: { md: '40px', sm: '35px', xs: '22px' },
                                    fontWeight: 700,
                                    textAlign: 'center',
                                    textShadow: '2px 2px black'
                                }}
                            >
                                Recent Venues
                            </Typography>
                        </Box>
                    </Box>
                    {/* Banner */}
                    {/* BODY */}
                    <Box
                        sx={{
                            m: isSmall ? '15px' : isMedium ? '30px 25px' : isLarge ? '80px' : '100px 116px'
                        }}
                    >
                        {isLoaded ?
                            <Grid container spacing={{ md: 3, xs: 2 }}>
                                {venues?.slice(page * rowsPerpage, page * rowsPerpage + rowsPerpage)?.map((venue) => (
                                    <Grid item lg={4} sm={6} xs={12} key={venue?._id}>
                                        <VenueCard venue={venue} />
                                    </Grid>
                                ))}
                            </Grid>
                            :
                            <Loader />
                        }
                        {venues?.length > rowsPerpage &&
                            <Box
                                component={'div'}
                                sx={{
                                    mt: '30px'
                                }}
                            >
                                <Pagination
                                    handleChangePage={handleChangePage}
                                    count={Math.ceil(venues?.length / rowsPerpage)}
                                />
                            </Box>
                        }
                    </Box>
                    {/* BODY */}
                </>
                :
                <Loader />
            }
        </>
    )
}

export default Venues