import dayjs from "dayjs";

export const formReducer = (state, event) => {
    return {
        ...state,
        [event.target?.name]: event.target?.value
    }
}

export const validateEmail = (email) => /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(email);

export const validatePassword = (password) => /^(?=.*[a-z])(?=.*[A-Z]).{8,16}$/.test(password);

export const validateDate = (date) => dayjs(date, 'MM/DD/YYYY', true).isValid();

export const getDuration = (slots, retunHours = false) => {
    let totalDisplay = '';
    let totalHoursMinutes = 0;
    Object.keys(slots)?.forEach(x => {
        totalHoursMinutes += slots[x]?.reduce((acc, timeSlot) => {
            const start = dayjs(timeSlot.start_mil);
            const end = dayjs(timeSlot.end_mil);
            const duration = end.diff(start, 'minutes');
            return acc + duration;
        }, 0);
    })
    const hours = Math.floor(totalHoursMinutes / 60);
    const days = Math.floor(hours / 24);
    const minutes = totalHoursMinutes % 60;
    if(retunHours) {
        return hours + (minutes/60);
    }
    if (days > 0) {
        totalDisplay += `${days}d`;
    }
    if (hours > 0) {
        totalDisplay += `${hours}h`;
    }
    if (minutes > 0) {
        totalDisplay += ` ${minutes}m`;
    }
    return totalDisplay;
}