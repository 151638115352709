import { Container, Typography, useTheme } from '@mui/material'
import React from 'react'
import CustomBtn from '../Components/CustomBtn'
import { useNavigate } from 'react-router'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSearchParams } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';

const Response = () => {
    const navigate = useNavigate();
    const [query] = useSearchParams();
    const { palette: { primary } } = useTheme();

    const status = query.get('status');

    return (
        <Container
            sx={{
                minHeight: '60vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '20px',
                flexDirection: 'column'
            }}
        >
            {status === 'success' ?
                <CheckCircleIcon
                    sx={{
                        fontSize: 160,
                        color: 'green'
                    }}
                />
                :
                <ErrorIcon
                    sx={{
                        fontSize: 160,
                        color: 'red'
                    }}
                />
            }
            <Typography
                component={'h1'}
                textAlign={'center'}
                fontWeight={600}
                fontSize={25}
            >
                {status === 'success' ? 'Transaction Successful' : 'Transaction Failed'}
            </Typography>
            <Typography
                component={'p'}
                textAlign={'center'}
            >
                {status === 'success' ? 'Your booking is completed!' : 'Your booking is failed please try again!'}
            </Typography>
            <CustomBtn
                title='View Bookings'
                btnStyle={{
                    borderRadius: '26px',
                    background: primary.darkBlue,
                    fontWeight: 500,
                    textTransform: 'capitalize',
                    fontSize: { lg: 16, xs: 14 },
                    p: { lg: '7px 35px', xs: '5px 25px' }
                }}
                onClick={() => navigate('/bookings')}
            />
        </Container>
    )
}

export default Response