import { Box, ButtonGroup, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useReducer, useState } from 'react'
import CustomBtn from '../Components/CustomBtn';
import Dropdown from '../Components/Dropdown';
import SearchIcon from '../Assets/svg/SearchIcon';
import VenueCard from '../Components/VenueCard';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import 'swiper/css';
// import TestimonialCard from '../Components/TestimonialCard';
// import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import PhoneIcon from '../Assets/svg/PhoneIcon';
import { Email } from '@mui/icons-material';
import InputField from '../Components/InputField';
import { formReducer } from '../utils/globalFunctions';
import { Link, useNavigate } from 'react-router-dom';
import { contactUs, getFacilities } from '../Endpoints/UserEndpoints';
import { useSelector } from 'react-redux';
import SnackAlert from '../Components/SnackAlert';

const Home = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { palette: { primary } } = theme;
    const isLarge = useMediaQuery('(max-width:1059px)');
    const isMedium = useMediaQuery('(max-width:680px)');
    const isSmall = useMediaQuery('(max-width:430px)');
    const xs = useMediaQuery(theme.breakpoints.down('sm'));
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const [type, setType] = useState();
    const [venues, setVenues] = useState([]);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    // const user = useSelector(state => state.auth.userData);
    const [formData, setFormData] = useReducer(formReducer, {});
    const categoriesList = useSelector(state => state.auth.categoriesList);

    const getVenues = async () => {
        try {
            const response = await getFacilities();
            setVenues(response?.data ?? []);
        } catch (e) {
            console.log("ERR", e)
        }
    }

    const handleSubmit = async () => {
        setIsSubmitted(true);
        if (formData['fName'] && formData['lName'] && formData['email'] && formData['subject'] && formData['message']) {
            setLoading(true);
            try {
                const data = {
                    name: `${formData['fName']} ${formData['lName']}`,
                    email: formData['email'],
                    subject: formData['subject'],
                    message: formData['message'],
                    phone: formData['phone'],
                }
                const response = await contactUs(data);
                if (response?.message === "Message send!") {
                    setType('success');
                    setMessage('Message sent successfully');
                    setShowAlert(true);
                    Object.keys(formData).forEach(x => setFormData({ target: { name: x, value: '' } }));
                } else {
                    setType('error');
                    setMessage(response?.message ?? 'Error occured please try again!');
                    setShowAlert(true);
                }
                setIsSubmitted(false);
                setLoading(false);
            } catch (err) {
                console.error(err)
                setType('error');
                setMessage('Error occured please try again!');
                setShowAlert(true);
                setIsSubmitted(false);
                setLoading(false);
            }
        }
    }

    const handleSearch = () => {
        if (formData['category'] && formData['facility']) {
            navigate(`venue/${formData['facility']?.split('+')?.[0]}/${formData['category']?.split('+')?.[1]}`)
            return;
        }
        if (formData['facility']) {
            navigate(`venue/${formData['facility']?.split('+')?.[0]}`)
            return;
        }
        if (formData['category']) {
            navigate(`category/${formData['category']?.split('+')?.[1]}`)
            return;
        }
    }

    useEffect(() => {
        getVenues();
    }, [])

    return (
        <>
            {/* Banner */}
            <Box
                sx={{
                    display: 'block',
                    height: { md: '600px', xs: isSmall ? '300px' : '400px' },
                    position: 'relative',
                    maxHeight: '600px',
                    backgroundColor: '#00000052',
                    '& img': {
                        height: { md: '600px', xs: isSmall ? '300px' : '400px' },
                        maxHeight: '600px',
                        width: '100%',
                        position: 'absolute',
                        zIndex: '-1',
                        objectFit: 'cover',
                    }
                }}
            >
                <img
                    src="/images/banner_img.png"
                    alt='banner'
                />
                <Box
                    sx={{
                        mt: '50px',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            color: primary.main,
                            fontFamily: 'Nunito Sans',
                            fontSize: { sm: '30px', xs: '22px' },
                            fontWeight: 900,
                            textAlign: 'center',
                            mx: '10px',
                            zIndex: 99,
                        }}
                    >
                        We Work With The Best Sports Facilities
                    </Typography>
                    <ButtonGroup
                        size="large"
                        sx={{
                            mt: '25px',
                            height: { sm: isLarge ? '50px' : '60px', xs: '40px' },
                            maxWidth: isSmall ? '300px' : isMedium ? '400px' : isLarge ? '650px' : 'auto',
                            '.MuiFormControl-root': {
                                width: '100%'
                            }
                        }}
                    >
                        <Dropdown
                            options={venues}
                            value={formData['facility']}
                            onChange={(e) => {
                                setFormData({
                                    target: {
                                        name: 'facility',
                                        value: e.target.value
                                    }
                                })
                            }}
                            fullWidth={false}
                            label='Facility'
                            inputStyle={{
                                '.MuiSelect-select': {
                                    fontSize: { sm: isLarge ? 14 : 18, xs: 12 },
                                    color: primary.main,
                                    padding: { sm: 'auto', xs: '7px' }
                                },
                                'fieldset': {
                                    borderColor: `${primary.main} !important`,
                                    borderRadius: 0,
                                },
                                '.MuiSvgIcon-root': {
                                    top: { sm: 'calc(50% - 1rem)', xs: 'calc(50% - 0.45em)' },
                                    width: { sm: '2rem', xs: '1.5rem' },
                                    height: { sm: '2rem', xs: '1.5rem' },
                                    right: { sm: '7px', xs: '2px' },
                                }
                            }}
                            containerStyle={{
                                minWidth: { sm: '200px', xs: '125px' },
                                '.MuiFormLabel-root': {
                                    top: { sm: isLarge ? 0 : '2px', xs: '-4px' },
                                    fontSize: { sm: isLarge ? 14 : 16, xs: 12 },
                                    color: primary.main,
                                    left: { sm: 0, xs: '3px' },
                                    maxWidth: { sm: 'calc(133% - 32px)', xs: '100%' }
                                },
                                '.MuiInputLabel-shrink': {
                                    top: 0,
                                },
                            }}
                        />
                        {/* <DateInput
                        inputStyle={{
                            'input': {
                                p: isSmall ? '10px' : isLarge ? '14px' : "17px 25px",
                                fontSize: isSmall ? 12 : isLarge ? 14 : 18,
                            }
                        }}
                    /> */}
                        <Dropdown
                            options={categoriesList}
                            value={formData['category']}
                            onChange={(e) => {
                                setFormData({
                                    target: {
                                        name: 'category',
                                        value: e.target.value
                                    }
                                })
                            }}
                            fullWidth={false}
                            label='Category'
                            inputStyle={{
                                '.MuiSelect-select': {
                                    fontSize: { sm: isLarge ? 14 : 18, xs: 12 },
                                    color: primary.main,
                                    padding: { sm: 'auto', xs: '7px' }
                                },
                                'fieldset': {
                                    borderColor: `${primary.main} !important`,
                                    borderRadius: 0,
                                },
                                '.MuiSvgIcon-root': {
                                    top: { sm: 'calc(50% - 1rem)', xs: 'calc(50% - 0.45em)' },
                                    width: { sm: '2rem', xs: '1.5rem' },
                                    height: { sm: '2rem', xs: '1.5rem' },
                                    right: { sm: '7px', xs: '2px' },
                                }
                            }}
                            containerStyle={{
                                minWidth: { sm: '200px', xs: '125px' },
                                '.MuiFormLabel-root': {
                                    top: { sm: isLarge ? 0 : '2px', xs: '-4px' },
                                    fontSize: { sm: isLarge ? 14 : 16, xs: 12 },
                                    color: primary.main,
                                    left: { sm: 0, xs: '3px' },
                                    maxWidth: { sm: 'calc(133% - 32px)', xs: '100%' }
                                },
                                '.MuiInputLabel-shrink': {
                                    top: 0,
                                },
                            }}
                        />
                        <CustomBtn
                            title={isMedium ? '' : 'SEARCH'}
                            disabled={!formData['category'] && !formData['facility']}
                            onClick={handleSearch}
                            btnStyle={{
                                borderColor: primary.main,
                                background: primary.main,
                                color: primary.darkBlue,
                                fontSize: isLarge ? 14 : 16,
                                p: isMedium ? 0 : isLarge ? '7px 45px' : '7px 55px',
                                pr: isMedium ? '10px' : 'auto',
                                minWidth: isSmall ? '30px' : '40px',
                                'svg': {
                                    width: isSmall ? '16px' : '20px',
                                    marginBottom: isSmall ? 0 : '2px'
                                },
                                '&:disabled': {
                                    backgroundColor: primary.main
                                }
                            }}
                            endIcon={<SearchIcon />}
                        />
                    </ButtonGroup>
                </Box>
            </Box>
            {/* Banner */}
            {/* Recent Venues */}
            <Box
                sx={{
                    mt: isMedium ? '30px' : isLarge ? '65px' : '99px'
                }}
            >
                <Typography
                    sx={{
                        color: primary.darkBlue,
                        textAlign: 'center',
                        fontFamily: "Nunito Sans",
                        fontSize: isSmall ? 20 : '32px',
                        fontWeight: 800,
                    }}
                >
                    Classess
                </Typography>
                <Typography
                    sx={{
                        mt: isSmall ? '3px' : isMedium ? '12px' : '22px',
                        color: '#595858',
                        textAlign: 'center',
                        fontFamily: "Nunito Sans",
                        fontSize: isSmall ? 14 : 16
                    }}
                >
                    Discover the classess from the below list.
                </Typography>
                <Box
                    sx={{
                        ml: isMedium ? '10px' : isLarge ? '40px' : '60px',
                        mr: isMedium ? '10px' : isLarge ? '40px' : '60px',
                        position: 'relative',
                        '.swiper': {
                            marginLeft: isMedium ? '35px' : isLarge ? '40px !important' : '60px !important',
                            marginRight: isMedium ? '35px' : isLarge ? '40px !important' : '60px !important',
                            pt: isSmall ? '20px !important' : isMedium ? '35px !important' : '45px !important',
                        }
                    }}
                >
                    <Swiper
                        spaceBetween={isMedium ? 10 : 30}
                        slidesPerView={xs ? 1 : matches ? 2 : 3}
                    >
                        <SlideButton type={'prev'} />
                        {venues?.map(x => <SwiperSlide key={x?._id}><VenueCard venue={x} /></SwiperSlide>)}
                        <SlideButton type={'next'} />
                    </Swiper>
                </Box>
                {venues?.length > 3 &&
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CustomBtn
                            btnStyle={{
                                mt: isSmall ? '20px' : '50px',
                                fontSize: isSmall ? 12 : 16,
                                p: isSmall ? '5px 20px' : '7px 35px',
                            }}
                            onClick={() => navigate('/venues')}
                            title='View More'
                        />
                    </Box>
                }
            </Box>
            {/* Recent Venues */}
            {/*  */}
            {/* <Grid
                container
                sx={{
                    pl: isMedium ? '0px' : isLarge ? '50px' : '116px',
                    pr: isMedium ? '0px' : isLarge ? '50px' : '116px',
                    mt: isMedium ? '30px' : '65px',
                    alignItems: 'center',
                }}
                spacing={'20px'}
            >
                {matches ?
                    <>
                        <Grid item md={6} xs={12} textAlign={'center'}>
                            <img
                                style={{
                                    maxHeight: '400px',
                                    height: 'auto',
                                    width: 'auto',
                                    maxWidth: '100%'
                                }}
                                src='/images/playing.png'
                                alt=''
                            />
                        </Grid>
                        <Grid item md={6} xs={12} textAlign={'center'} px={'50px !important'}>
                            <Typography
                                sx={{
                                    color: '#15192C',
                                    fontSize: isSmall ? '20px' : isLarge ? '25px' : '28px',
                                    fontWeight: 800,
                                    fontFamily: "Nunito Sans",
                                }}
                            >
                                1000+ Facilities across the UK and Ireland
                            </Typography>
                            <Typography
                                color='#202020'
                                fontSize={isSmall ? '12px' : '16px'}
                                mt={isLarge ? '10px' : '30px'}
                                mb={isLarge ? '10px' : '30px'}
                                fontFamily={"Nunito Sans"}
                            >
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolor
                            </Typography>
                            <CustomBtn
                                title='BOOK TODAY'
                                onClick={() => navigate(user ? '/bookings' : '/login')}
                                btnStyle={{
                                    fontSize: isSmall ? '12px' : '16px',
                                    p: isSmall ? '5px 20px' : isLarge ? '7px 20px' : '7px 35px',
                                    mt: isMedium ? '10px' : '20px'
                                }}
                            />
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item md={6} xs={12} textAlign={'left'}>
                            <Typography
                                sx={{
                                    color: '#15192C',
                                    fontSize: isLarge ? '25px' : '28px',
                                    fontWeight: 800,
                                    fontFamily: "Nunito Sans",
                                }}
                            >
                                1000+ Facilities across the UK and Ireland
                            </Typography>
                            <Typography
                                color='#202020'
                                mt={isLarge ? '10px' : '30px'}
                                mb={isLarge ? '10px' : '30px'}
                                fontFamily={"Nunito Sans"}
                            >
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolor
                            </Typography>
                            <CustomBtn
                                onClick={() => navigate(user ? '/bookings' : '/login')}
                                title='BOOK TODAY'
                                btnStyle={{
                                    fontSize: '16px',
                                    mt: isLarge ? '10px' : '0px',
                                    p: isLarge ? '7px 20px' : '7px 35px'
                                }}
                            />
                        </Grid>
                        <Grid item md={6} xs={12} textAlign={'right'}>
                            <img
                                style={{
                                    maxHeight: '400px',
                                    height: 'auto',
                                    width: 'auto',
                                    maxWidth: '100%'
                                }}
                                src='/images/playing.png'
                                alt=''
                            />
                        </Grid>
                    </>
                }
            </Grid> */}
            {/*  */}
            {/* Testimonials */}
            {/* <Box
                sx={{
                    mt: isMedium ? '30px' : isLarge ? '65px' : '99px',
                }}
            >
                <Box
                    sx={{
                        gap: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Box
                        sx={{
                            height: '1px',
                            width: '25px',
                            background: '#202020'
                        }}
                    />
                    <Typography
                        sx={{
                            color: '#202020',
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            fontFamily: "Nunito Sans",
                            fontSize: isSmall ? 12 : 16
                        }}
                    >
                        Testimonials
                    </Typography>
                </Box>
                <Typography
                    sx={{
                        color: primary.darkBlue,
                        textAlign: 'center',
                        fontFamily: "Nunito Sans",
                        fontSize: isSmall ? 20 : 32,
                        fontWeight: 800,
                    }}
                >
                    What they say about us
                </Typography>

                <Box
                    sx={{
                        mt: isMedium ? '30px' : '55px',
                        mx: isSmall ? '10px' : isMedium ? '20px' : '50px',
                        position: 'relative',
                        '.swiper': {
                            pb: '55px !important'
                        }
                    }}
                >
                    <Swiper
                        spaceBetween={isMedium ? 20 : 30}
                        slidesPerView={isSmall ? 1 : matches ? xs ? 1 : 2 : 3}
                        pagination={true}
                        modules={[Pagination]}
                    >
                        {[0, 1, 2, 3, 4].map(x => <SwiperSlide key={x}><TestimonialCard /></SwiperSlide>)}
                        {[0, 1, 2, 3, 4].map(x => <SwiperSlide key={x}><TestimonialCard /></SwiperSlide>)}
                    </Swiper>
                </Box>
            </Box> */}
            {/* Testimonials */}
            {/* Contact Us */}
            <Box
                sx={{
                    mt: isMedium ? '30px' : isLarge ? '65px' : '99px',
                }}
                component={'div'}
                id='support'
            >
                <Typography
                    sx={{
                        color: primary.darkBlue,
                        textAlign: 'center',
                        fontFamily: "Nunito Sans",
                        fontSize: isSmall ? 20 : '32px',
                        fontWeight: 800,
                    }}
                >
                    Contact Us
                </Typography>
            </Box>
            <Box
                sx={{
                    mb: '60px',
                    mt: isSmall ? 0 : isMedium ? '30px' : '55px',
                    mx: isSmall ? '10px' : isMedium ? '20px' : isLarge ? '50px' : '116px',
                }}
            >
                <Grid
                    container
                    m={'auto'}
                    maxWidth={'1200px'}
                    boxShadow={{ sm: '0px 4px 30px 0px rgba(97, 97, 97, 0.27)', xs: 'none' }}
                >
                    <Grid item xs={4} display={isMedium ? 'none' : 'block'}>
                        <Box
                            sx={{
                                background: primary.darkBlue,
                                p: matches ? '10px' : 0,
                                pt: isLarge ? '50px' : '67px',
                                pl: matches ? '10px' : isLarge ? '25px' : '71px',
                                height: '100%',
                                position: 'relative',
                                '& img': {
                                    width: { lg: '300px', xs: '250px' },
                                }
                            }}
                        >
                            <Typography
                                sx={{
                                    color: primary.main,
                                    fontSize: matches ? '25px' : '30px',
                                    fontWeight: 700
                                }}
                            >
                                Get In Touch
                            </Typography>
                            <Box
                                sx={{
                                    background: primary.main,
                                    height: 3,
                                    width: 50,
                                    mt: matches ? '15px' : '24px'
                                }}
                            />
                            <Box
                                sx={{
                                    mt: matches ? '20px' : '33px',
                                    gap: matches ? '6px' : '12px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <PhoneIcon width={'16'} />
                                <Link
                                    to={`tel:+974-33144266`}
                                    style={{
                                        color: primary.main,
                                        fontSize: 16,
                                        fontWeight: 700,
                                        textDecoration: 'none'
                                    }}
                                >
                                    +974 3314 4266
                                </Link>
                            </Box>
                            <Box
                                sx={{
                                    mt: matches ? '10px' : '18px',
                                    gap: matches ? '6px' : '12px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Email
                                    sx={{
                                        color: primary.main,
                                        width: '20px'
                                    }}
                                />
                                <Link
                                    to={`mailto:Sales@playit.qa`}
                                    style={{
                                        color: primary.main,
                                        fontSize: 16,
                                        fontWeight: 700,
                                        textDecoration: 'none'
                                    }}
                                >
                                    Sales@playit.qa
                                </Link>
                            </Box>
                            <img
                                src='/images/football.webp'
                                alt=''
                                style={{
                                    height: 'auto',
                                    position: 'absolute',
                                    bottom: isMedium ? '-64px' : '-92px',
                                    left: isMedium ? '-16px' : '-63px'
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={isMedium ? 12 : 8}>
                        <Box
                            sx={{
                                background: primary.main,
                                p: isSmall ? '20px 10px' : isLarge ? '50px 25px' : '55px 50px',
                                pb: isSmall ? '20px' : isMedium ? '50px' : '100px'
                            }}
                        >
                            <Grid container spacing={isLarge ? '15px' : '30px'}>
                                <Grid item md={6} xs={12}>
                                    <InputField
                                        label='First Name'
                                        required={true}
                                        isSubmitted={isSubmitted}
                                        name='fName'
                                        value={formData['fName']}
                                        onChange={setFormData}
                                        inputStyle={{
                                            p: isMedium ? '5px 10px' : '10px 20px',
                                            fontSize: isSmall ? 12 : isMedium ? 14 : 16,
                                        }}
                                        labelStyle={{
                                            fontSize: isSmall ? 12 : isMedium ? 14 : 16,
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <InputField
                                        label='Last Name'
                                        isSubmitted={isSubmitted}
                                        required={true}
                                        name='lName'
                                        value={formData['lName']}
                                        onChange={setFormData}
                                        inputStyle={{
                                            p: isMedium ? '5px 10px' : '10px 20px',
                                            fontSize: isSmall ? 12 : isMedium ? 14 : 16,
                                        }}
                                        labelStyle={{
                                            fontSize: isSmall ? 12 : isMedium ? 14 : 16,
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <InputField
                                        label='Email'
                                        isSubmitted={isSubmitted}
                                        required={true}
                                        type='email'
                                        name='email'
                                        value={formData['email']}
                                        onChange={setFormData}
                                        inputStyle={{
                                            p: isMedium ? '5px 10px' : '10px 20px',
                                            fontSize: isSmall ? 12 : isMedium ? 14 : 16,
                                        }}
                                        labelStyle={{
                                            fontSize: isSmall ? 12 : isMedium ? 14 : 16,
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <InputField
                                        type='phone'
                                        label='Phone'
                                        name='phone'
                                        value={formData['phone']}
                                        onChange={setFormData}
                                        inputStyle={{
                                            p: isMedium ? '5px 10px' : '10px 20px',
                                            fontSize: isSmall ? 12 : isMedium ? 14 : 16,
                                        }}
                                        labelStyle={{
                                            fontSize: isSmall ? 12 : isMedium ? 14 : 16,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputField
                                        label='Subject'
                                        required={true}
                                        isSubmitted={isSubmitted}
                                        name='subject'
                                        value={formData['subject']}
                                        onChange={setFormData}
                                        inputStyle={{
                                            p: isMedium ? '5px 10px' : '10px 20px',
                                            fontSize: isSmall ? 12 : isMedium ? 14 : 16,
                                        }}
                                        labelStyle={{
                                            fontSize: isSmall ? 12 : isMedium ? 14 : 16,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputField
                                        minRows={4}
                                        multiline={true}
                                        required={true}
                                        isSubmitted={isSubmitted}
                                        label='Message'
                                        name='message'
                                        value={formData['message']}
                                        onChange={setFormData}
                                        inputStyle={{
                                            p: isMedium ? '5px 10px' : '10px 20px',
                                            fontSize: isSmall ? 12 : isMedium ? 14 : 16,
                                        }}
                                        labelStyle={{
                                            fontSize: isSmall ? 12 : isMedium ? 14 : 16,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <CustomBtn
                                loading={loading}
                                title='Send Message'
                                onClick={handleSubmit}
                                btnStyle={{
                                    mt: isMedium ? '25px' : '50px',
                                    p: { sm: '7px 35px', xs: '5px 20px' },
                                    fontSize: isSmall ? 12 : isMedium ? 14 : 16,
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {/* Contact Us */}
            <SnackAlert
                type={type}
                open={showAlert}
                message={message}
                handleClose={() => setShowAlert(false)}
            />
        </>
    )
}


const SlideButton = ({ type }) => {
    const swiper = useSwiper();
    return (
        <>
            {type === 'prev' ?
                <Typography
                    onClick={() => swiper.slidePrev()}
                    className='button left'
                >
                    <ArrowBackIcon
                        sx={{
                            width: { sm: '2rem', xs: '1.5rem' },
                            height: { sm: '2rem', xs: '1.5rem' },
                        }}
                    />
                </Typography>
                :
                <Typography
                    onClick={() => swiper.slideNext()}
                    className='button right'
                >
                    <ArrowForwardIcon
                        sx={{
                            width: { sm: '2rem', xs: '1.5rem' },
                            height: { sm: '2rem', xs: '1.5rem' },
                        }}
                    />
                </Typography>
            }
        </>
    );
}

export default Home