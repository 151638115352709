import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import BookingCard from '../Components/BookingCard';
import { getSportDetailsByName, searchListing } from '../Endpoints/UserEndpoints';
import { useNavigate, useParams } from 'react-router-dom';
import { url } from '../utils/Host';
import Loader from '../Components/Loader';
import Pagination from '../Components/Pagination';

const VenueDetails = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { palette: { primary } } = useTheme();
    const isSmall = useMediaQuery('(max-width:430px)');
    const isLarge = useMediaQuery('(max-width:1059px)');
    const isMedium = useMediaQuery('(max-width:680px)');

    const rowsPerpage = 6;
    const [sports, setSports] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [sportDetails, setSportDetails] = useState(null);

    const handleChangePage = (pageNumber) => {
        if (pageNumber > sports.CurrentPage && sports.serviceClassList.length <= sports.currentTotalItem) {
            getSportByVenueId(pageNumber);
        } else if (sports.serviceClassList.length > sports.currentTotalItem && pageNumber > sports.CurrentPage) {
            let temp = JSON.parse(JSON.stringify(sports));
            temp.CurrentPage = pageNumber;
            temp.Next = (sports.endOfItem + 6) < sports.totalInDb;
            temp.Previous = true;
            temp.startOfItem = sports.startOfItem + 6;
            temp.endOfItem = ((sports.endOfItem + 6) > sports.totalInDb) ? sports.totalInDb : (sports.endOfItem + 6);
            temp.currentTotalItem = sports.totalInDb - sports.currentTotalItem;
            setSports(temp);
        } else {
            let temp = JSON.parse(JSON.stringify(sports));
            temp.CurrentPage = pageNumber;
            temp.Next = true;
            temp.Previous = sports.startOfItem > 7;
            temp.startOfItem = sports.startOfItem - 6;
            temp.endOfItem = sports.startOfItem - 1;
            temp.currentTotalItem = sports.totalInDb - sports.currentTotalItem;
            setSports(temp);
        }
    };

    const getSportByVenueId = async (page, storePrevious = true) => {
        setIsLoaded(false);
        try {
            let data = { page: String(page), }
            if (params?.category) {
                data = { ...data, category: params?.category };
            }
            if (params?.id) {
                data = { ...data, facility: params?.id };
            }
            console.log(data)
            const response = await searchListing(data);
            if (response?.results) {
                if (sports?.serviceClassList?.length > 0 && storePrevious) {
                    let temp = JSON.parse(JSON.stringify(response?.results));
                    temp.serviceClassList = [...sports.serviceClassList, ...temp.serviceClassList]
                    setSports(temp);
                } else {
                    setSports(response?.results ?? null);
                }
            }
            setIsLoaded(true);
        } catch (e) {
            console.log("ERR", e)
        }
    }

    const getSportDetails = async () => {
        try {
            const response = await getSportDetailsByName(params?.category);
            setSportDetails(response?.data ?? null)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (params?.category) {
            getSportDetails()
            getSportByVenueId(1, false);
        } else {
            getSportByVenueId(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params?.category])

    return (
        <>
            {isLoaded || (!isLoaded && sports?.serviceClassList.length > 0) ?
                <>
                    {/* Banner */}
                    <Box
                        sx={{
                            background: `linear-gradient(0deg, rgb(23 130 202 / 0%) 0%, rgb(23 130 202 / 0%) 100%), url('${url}/picAdmin/${sportDetails?.ProfileImage ?? sports?.serviceClassList[0]?.facilitiesDetails[0]?.Images[0]}'), lightgray 50% / cover no-repeat`,
                            width: '100%',
                            height: { md: '600px', xs: isSmall ? '300px' : '400px' },
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                py: isSmall ? '5px' : isLarge ? '10px' : '15px',
                                mt: 'auto',
                                width: '100%',
                                borderRadius: '0px 0px 5px 5px',
                                background: 'rgba(0, 0, 0, 0.25)',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: primary.main,
                                    fontFamily: 'Nunito Sans',
                                    fontSize: { md: '40px', sm: '35px', xs: '22px' },
                                    fontWeight: 700,
                                    textAlign: 'center',
                                    textShadow: '2px 2px black'
                                }}
                            >
                                {sportDetails?.Category ?? sports?.serviceClassList[0]?.facilitiesDetails[0]?.Name}
                            </Typography>
                        </Box>
                    </Box>
                    {/* Banner */}
                    {/* BODY */}
                    <Box
                        sx={{
                            m: isSmall ? '15px' : isMedium ? '30px 25px' : isLarge ? '80px' : '100px 116px'
                        }}
                    >
                        {isLoaded ?
                            sports?.CurrentPage ?
                                <Grid container spacing={{ md: 3, xs: 2 }}>
                                    {sports?.serviceClassList?.slice(sports?.startOfItem - 1, sports?.endOfItem)?.map((sport) => (
                                        <Grid item lg={4} sm={6} xs={12} key={sport?._id}>
                                            <BookingCard
                                                onClick={() => navigate(`/service/${sport?._id}`)}
                                                showActionBtn={true}
                                                item={{
                                                    ...sport,
                                                    image: sport?.Images?.length > 0 ? sport?.Images[0] : undefined,
                                                    sportName: sport?.Name,
                                                    facilityName: sport?.facilitiesDetails?.length > 0 ? sport?.facilitiesDetails[0]?.Name : '',
                                                    facilityAddress: sport?.facilitiesDetails?.length > 0 ? sport?.facilitiesDetails[0]?.Address : '',
                                                }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                :
                                <Typography
                                    sx={{
                                        fontWeight: 600,
                                        textAlign: 'center',
                                        fontSize: 18
                                    }}
                                >
                                    No Service or class is currently available in this category
                                </Typography>
                            :
                            <Loader />
                        }
                        {sports?.totalInDb > rowsPerpage &&
                            <Box
                                component={'div'}
                                sx={{
                                    mt: '30px'
                                }}
                            >
                                <Pagination
                                    handleChangePage={handleChangePage}
                                    count={Math.ceil(sports?.totalInDb / 6)}
                                />
                            </Box>
                        }
                    </Box>
                    {/* BODY */}
                </>
                :
                <Loader />
            }
        </>
    )
}

export default VenueDetails