import { Card, CardContent, Typography } from '@mui/material'
import React from 'react'

const CardWrapper = ({ title, children }) => {
    return (
        <Card
            sx={{
                minHeight: { lg: '543px', md: '570px', sm: '527px', xs: 'auto' },
                p: { xl: '20px 25px', sm: '10px 15px', xs: '10px' },
                height: 'auto',
                pb: { sm: 0, xs: '10px' },
                maxWidth: '100%',
                borderRadius: '5px',
                border: '1px solid #E6E6E6',
                background: '#FDFDFD',
                boxShadow: '0px 2px 6px 1px rgba(0, 0, 0, 0.10)',
                position: 'relative',
            }}
        >
            <CardContent
                sx={{
                    p: { xl: '16px', xs: '0px' },
                    pt: { xl: '16px', xs: '20px' }
                }}
            >
                <Typography fontSize={{ lg: '26px', xs: '26px' }} fontWeight={700} component="div" color={'#15192C'}>
                    {title}
                </Typography>
                {children}
            </CardContent>
        </Card>
    )
}

export default CardWrapper